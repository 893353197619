const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');

export const measureTextWidth = (text: string, fontFamily: string, fontSize: number): number => {
  if(context) {
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  } else {
    return fontSize;
  }
}