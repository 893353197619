
import { actionCreatorFactory } from 'typescript-fsa';
import { asyncFactory, thunkToAction } from 'typescript-fsa-redux-thunk';

import { SceneLoadedEvent, UserActiveEventArgs } from '../../models';
import { AuthTokenState, RootState } from '../models';
import { loadSceneWorker } from './asyncWorkers/loadSceneWorker';


enum Type {
  LoadScene = 'LOAD_SCENE',
  NativeFullscreen = 'NATIVE_FULLSCREEN',
  NativeFullscreenSupported = 'NATIVE_FULLSCREEN_SUPPORTED',
  ToggleSubtitles = 'TOGGLE_SUBTITLES',
  UnloadScene = 'UNLOAD_SCENE',
  UserActive = 'USER_ACTIVE',
  UserInactive = 'USER_INACTIVE',
  UtilityServiceConnected = 'UTILITY_SERVICE_CONNECTED',
  UtilityServiceFullscreen = 'UTILITY_SERVICE_FULLSCREEN',
  SelectedSubtitleLanguage = 'SELECTED_SUBTITLE_LANGUAGE',
  UpdateAuthToken = 'UPDATE_AUTH_TOKEN'
}

const create = actionCreatorFactory('CONTEXT');
const createAsync = asyncFactory<RootState>(create);

const unloadScene = create(Type.UnloadScene);

// Async Action Creators
export const asyncActionCreators = {
  loadSceneCreator: createAsync<any, SceneLoadedEvent>(Type.LoadScene, loadSceneWorker(unloadScene)) 
};

// Action Creators
export const actionCreators = {
  nativeFullscreen: create<boolean>(Type.NativeFullscreen),
  nativeFullscreenSupported: create<boolean>(Type.NativeFullscreenSupported),
  loadScene: thunkToAction(asyncActionCreators.loadSceneCreator.action),
  toggleSubtitles: create<boolean>(Type.ToggleSubtitles),
  unloadScene,
  userActive: create<UserActiveEventArgs>(Type.UserActive),
  userInactive: create(Type.UserInactive),
  utilityServiceConnected: create<boolean>(Type.UtilityServiceConnected),
  utilityServiceFullscreen: create<boolean>(Type.UtilityServiceFullscreen),
  selectedSubtitleLanguage: create<string>(Type.SelectedSubtitleLanguage),
  updateAuthToken: create<AuthTokenState>(Type.UpdateAuthToken),
};