import * as React from 'react';

import { FunctionCallService, FunctionCallServiceContext } from '../services/FunctionCallServiceProvider';

export interface withFunctionCallServiceProps {
  callParentFunction: FunctionCallService;
  playerId: FunctionCallService;
  collectAnalytics: FunctionCallService;
}

export const withFunctionCallService = <ComposedComponentProps extends withFunctionCallServiceProps>(ComposedComponent: React.ComponentClass<any>) => {
  type ComposedComponentInstance = InstanceType<typeof ComposedComponent>;

  type WrapperComponentProps = Omit<ComposedComponentProps, 'callParentFunction'|'playerId'|'collectAnalytics'|'pause'|'play'|'loadScene'|'seek'|'invokeInteractiveAction'>;
  type WrapperComponentPropsWithForwardedRef = WrapperComponentProps & {
    forwardedRef: React.Ref<ComposedComponentInstance>;
  };

  const WrapperComponent = ({ forwardedRef, ...rest }: WrapperComponentPropsWithForwardedRef) => {
    const context = React.useContext(FunctionCallServiceContext);

    return (
      <ComposedComponent
        ref={forwardedRef}
        functionCall={context}
        {...rest as unknown as ComposedComponentProps}
      />
    );
  };

  return React.forwardRef<ComposedComponentInstance, WrapperComponentProps>((props, ref) => <WrapperComponent forwardedRef={ref} {...props} />);
}