import * as React from 'react';
import { IAnalytics } from '../models';

import { UtilityServiceContext } from '../services/UtilityServiceProvider';

interface withGoogleAnalytics {
    sendGoogleAnalytics: (data: IAnalytics) => void;
}

export const withGoogleEvents = <ComposedComponentProps extends withGoogleAnalytics>(ComposedComponent: React.ComponentClass<ComposedComponentProps>) => {
  type ComposedComponentInstance = InstanceType<typeof ComposedComponent>;

  type WrapperComponentProps = Omit<ComposedComponentProps, 'sendGoogleAnalytics'>;
  type WrapperComponentPropsWithForwardedRef = WrapperComponentProps & {
    forwardedRef: React.Ref<ComposedComponentInstance>;
  };

  const WrapperComponent = ({ forwardedRef, ...rest }: WrapperComponentPropsWithForwardedRef) => {
    const context = React.useContext(UtilityServiceContext);

    const sendAnalytics = (data: IAnalytics) => {
        context.sendAnalytics(data);
    }

    return (
      <ComposedComponent
        ref={forwardedRef}
        {...rest as unknown as ComposedComponentProps}
        sendGoogleAnalytics={sendAnalytics}
      />
    );
  };

  return React.forwardRef<ComposedComponentInstance, WrapperComponentProps>((props, ref) => <WrapperComponent forwardedRef={ref} {...props} />);
}
