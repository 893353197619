import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { VideoActions } from "../redux/actions";
import { selectIsPlayerLoading } from "../redux/selectors";
import { RootState } from "../redux/models";
import ButtonBase from "./ButtonBase";
import styles from "./LargePlayButton.module.scss";

export interface LargePlayButtonProps {
  isLoading: boolean;
  play: typeof VideoActions.actionCreators.play;
  hasStarted: boolean;
}

class LargePlayButton extends React.Component<LargePlayButtonProps> {
  public render = () => {
    const { hasStarted, isLoading } = this.props;

    const className = classNames(styles["root"], {
      [styles["visible"]]: !hasStarted,
      [styles["hidden"]]: isLoading,
    });

    return (
      <ButtonBase  className={className} onClick={this.handleClick}>
        <svg id="img-play_arrow"
          width="95"
          height="111"
          viewBox="0 0 95 111"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.882 11.618L0.401001 0.949951V67.5099L18.882 78.18V11.618Z"
            fill="url(#paint0_linear_103_2070)"
          />
          <path
            d="M0.431946 110.402L58.05 77.0749L58.034 55.7339L0.412964 89.0619L0.431946 110.402Z"
            fill="url(#paint1_linear_103_2070)"
          />
          <path
            d="M37.299 22.3099L18.816 32.9739L76.516 66.3109L95 55.6449L37.299 22.3099Z"
            fill="url(#paint2_linear_103_2070)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_103_2070"
              x1="3.72758"
              y1="8.98187"
              x2="31.6202"
              y2="18.5987"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_103_2070"
              x1="16.4937"
              y1="96.6802"
              x2="52.8133"
              y2="60.8138"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_103_2070"
              x1="90.7337"
              y1="57.2907"
              x2="53.0326"
              y2="12.328"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
          </defs>
        </svg>
      </ButtonBase>
    );
  };

  private handleClick = () => {
    this.props.play();
  };
}

const mapStateToProps = (
  state: RootState
): Pick<LargePlayButtonProps, "isLoading" | "hasStarted"> => ({
  isLoading: selectIsPlayerLoading(state),
  hasStarted: state.context.hasStarted,
});

const mapDispatchToProps = (
  dispatch: Dispatch
): Pick<LargePlayButtonProps, "play"> => ({
  play: bindActionCreators(VideoActions.actionCreators.play, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LargePlayButton);
