import { CloudDownload } from '@mui/icons-material';
import { EvaluatedVideoSource, VideoSourceType } from '@videosmart/player-template';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { EndpointActions, VideoActions } from '../redux/actions';
import { RootState } from '../redux/models';
import { selectCurrentVideoSrc } from '../redux/selectors';
import ButtonBase from './ButtonBase';
import styles from './DownloadButton.module.scss';
import ErrorIcon from '@mui/icons-material/Error';
import classNames from 'classnames';
import { Tooltip } from '@mui/material';

export interface DownloadButtonProps {
  currentVideoSrc?: EvaluatedVideoSource;
  logDownload: typeof VideoActions.actionCreators.logDownload;
  signAsset: typeof EndpointActions.actionCreators.signAsset;
}

interface DownloadButtonState {
  downloadFailed: boolean;
}

class DownloadButton extends React.Component<DownloadButtonProps, DownloadButtonState> {
  private _iframeRef: React.RefObject<HTMLIFrameElement>;
  private _defaultFileName: string;

  constructor(props: DownloadButtonProps) {
    super(props);

    this.state = {
      downloadFailed: false,
    };

    this._iframeRef = React.createRef();
    this._defaultFileName = "Your personalised video.mp4";
  }

  public render = () => {
    const { currentVideoSrc } = this.props;
    const rootClassName = classNames(styles["root"]);
    const errorClassName = classNames(styles["error"]);
    const { downloadFailed } = this.state;
    return (
      <ButtonBase
        disabled={!currentVideoSrc}
        onClick={this.handleClick}
        className={rootClassName}
      >
        <iframe title={"Video downloader"} ref={this._iframeRef} style={{ display: 'none' }} />
       
        {downloadFailed ? 
          (
            <>
              <Tooltip arrow title="Oh no! The download failed. Please attempt again!" placement="top">
                <div>
                  <CloudDownload />
                  <ErrorIcon className={errorClassName}/>
                </div>
              </Tooltip>
            </>
          )
        :
          (
            <CloudDownload />
          )
        }
      </ButtonBase>
    );
  }

  private getPlayerApiDownloadFileEndpoint = (videoSrc: string) => {
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
    return `${baseUrl}/api/download/${encodeURIComponent(videoSrc)}?fileName=${this._defaultFileName}`;
  }

  private checkIframeDownloadStatus = (body? : HTMLElement) => {
    // If Iframe innerText is set it means the API returned BadRequest with message: "~cannot be downloaded"
    if(body?.innerText){
      this.setState( {
        downloadFailed: true
      });
    } else {
      this.setState( {
        downloadFailed: false
      });
    }
  }

  private isIE = () => {
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    return navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
  }

  private handleClick = async () => {
    const { currentVideoSrc, logDownload, signAsset } = this.props;
    if(this._iframeRef.current && currentVideoSrc && currentVideoSrc.type === VideoSourceType.S3) {
      const result = await signAsset({
        downloadFileName: this._defaultFileName,
        s3Key:  currentVideoSrc.s3Key,
        s3Bucket: currentVideoSrc.s3Bucket,
        s3Region: currentVideoSrc.s3Region
      });
      this._iframeRef.current.src = result.url;
    }

    else if(this._iframeRef.current && currentVideoSrc && currentVideoSrc.type === VideoSourceType.Url) {
      
      if(!this.isIE()){
        // Reset innerText of iframe
        if(this._iframeRef.current.contentDocument){
          this._iframeRef.current.contentDocument.body.innerText = "";
        }
      }
      
      this._iframeRef.current.src = this.getPlayerApiDownloadFileEndpoint(encodeURIComponent(currentVideoSrc.source));
      
      // The onload event is not fired if the content is an attachment i.e. in this specific case it only fires if the download failed from the API. 
      this._iframeRef.current.onload = () => {
        if(!this.isIE()){
          this.checkIframeDownloadStatus(this._iframeRef.current?.contentDocument?.body);
        }
      }
      
      if(!this.isIE()){
        // As the onload event is only fired upon download failure, this method is called again to set the  correct value to state.downloadFailed  
        this.checkIframeDownloadStatus(this._iframeRef.current?.contentDocument?.body);
        if(this._iframeRef.current?.contentDocument?.body){
          this.checkIframeDownloadStatus(this._iframeRef.current?.contentDocument?.body);
        }
      }
    }
    else {
      // This shouldn't ever happen as only the types: VideoSourceType.S3 and VideoSourceType.Url are available.
      return;
    }

    logDownload();
  }
}

const mapStateToProps = (state: RootState): Pick<DownloadButtonProps, 'currentVideoSrc'> => ({
  currentVideoSrc: selectCurrentVideoSrc(state)
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<DownloadButtonProps, 'logDownload' | 'signAsset'> => ({
  logDownload: bindActionCreators(VideoActions.actionCreators.logDownload, dispatch),
  signAsset: bindActionCreators(EndpointActions.actionCreators.signAsset, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadButton);
