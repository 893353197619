import { AsyncWorker } from 'typescript-fsa-redux-thunk';
import { getAuthToken } from '.';
import { WithStatus } from '../../../models';
import { PostEventBatchRequest } from '../../../models/requests';
import { withStatus } from '../../../utils';
import { RootState } from '../../models';

export const postEventsWorker = (apiRoot: string): AsyncWorker<PostEventBatchRequest, WithStatus<{}>, RootState> => async ({ async, events }, dispatch, getState) => {
  const data = getState().data;

  if (!data.isLoaded) {
    throw new Error('Load user data before trying to send events!');
  }

  const url = `${apiRoot}/events/${data.sessionId}`;
  const body = JSON.stringify({ events })

  if (async) {
    //get authToken
    const token = await getAuthToken("player.write", getState, dispatch);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        'Authorization': `${token}`
      },
      body
    });

    if (!response.ok) {
      throw new Error('Failed to send events!');
    }

    const result = withStatus({
      ok: response.ok,
      status: response.status
    })({});

    return result;
  } else {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, false);
    xhr.setRequestHeader("Content-Type", "application/json");

    const responseHandler = () => {
      return withStatus({
        ok: xhr.status >= 200 && xhr.status <= 299,
        status: xhr.status
      })({});
    };

    const request = new Promise<any>(function (resolve, reject) {
      xhr.onload = resolve;
      xhr.onerror = reject;
    }).then(responseHandler, responseHandler);

    //get authtoken and then send request
    getAuthToken("player.write", getState, dispatch)
    .then((e)=>{
      xhr.setRequestHeader("Authorization", `${e}`);
      xhr.send(body);
    });

    return request;
  }
};