import * as React from 'react';

import { FullscreenService, FullscreenServiceContext } from '../services';

interface WithFullscreenService {
  fullscreenService: FullscreenService;
}

export const withFullscreenService = <ComposedComponentProps extends WithFullscreenService>(ComposedComponent: React.ComponentClass<ComposedComponentProps>) => {
  type ComposedComponentInstance = InstanceType<typeof ComposedComponent>;

  type WrapperComponentProps = Omit<ComposedComponentProps, 'fullscreenService'>;
  type WrapperComponentPropsWithForwardedRef = WrapperComponentProps & {
    forwardedRef: React.Ref<ComposedComponentInstance>;
  };

  const WrapperComponent = ({ forwardedRef, ...rest }: WrapperComponentPropsWithForwardedRef) => {
    const context = React.useContext(FullscreenServiceContext);

    return (
      <ComposedComponent
        ref={forwardedRef}
        // We need a cast because:
        // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32355
        // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
        {...rest as unknown as ComposedComponentProps}
        fullscreenService={context}
      />
    );
  };

  return React.forwardRef<ComposedComponentInstance, WrapperComponentProps>((props, ref) => <WrapperComponent forwardedRef={ref} {...props} />);
}