import { isMobile } from 'react-device-detect';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { ContextActions, VideoActions } from '../actions';
import { ContextState } from '../models/ContextState';

const initialState: ContextState = {
  areSubtitlesEnabled: false,
  currentSceneIndex: undefined,
  hasStarted: false,
  isNativeFullscreen: false,
  isNativeFullscreenSupported: false,
  isMobile: isMobile,
  isUserActive: false,
  isUtilityServiceConnected: false,
  isUtilityServiceFullscreen: false,
  selectedSubtitleLanguage: "off",
  authToken: undefined
};

export const contextReducer = reducerWithInitialState(initialState)
  // Context
  .case(ContextActions.actionCreators.selectedSubtitleLanguage, (state, payload) => ({
    ...state,
    selectedSubtitleLanguage: payload
  }))
  .case(ContextActions.actionCreators.nativeFullscreen, (state, payload) => ({
    ...state,
    isNativeFullscreen: payload
  }))
  .case(ContextActions.actionCreators.nativeFullscreenSupported, (state, payload) => ({
    ...state,
    isNativeFullscreenSupported: payload
  }))
  .case(ContextActions.asyncActionCreators.loadSceneCreator.async.done, (state, { result }) => ({
    ...state,
    currentSceneIndex: result.sceneIndex
  }))
  .case(ContextActions.actionCreators.toggleSubtitles, (state, payload) => ({
    ...state,
    areSubtitlesEnabled: payload
  }))
  .case(ContextActions.actionCreators.userActive, (state) => ({
    ...state,
    isUserActive: true
  }))
  .case(ContextActions.actionCreators.userInactive, (state) => ({
    ...state,
    isUserActive: false
  }))
  .case(ContextActions.actionCreators.utilityServiceConnected, (state, payload) => ({
    ...state,
    isUtilityServiceConnected: payload
  }))
  .case(ContextActions.actionCreators.utilityServiceFullscreen, (state, payload) => ({
    ...state,
    isUtilityServiceFullscreen: payload
  }))

  // Video
  .case(VideoActions.actionCreators.logIsPlaying, (state, payload) => !state.hasStarted && payload ? ({
    ...state,
    hasStarted: true
  }) : state)

  .case(ContextActions.actionCreators.updateAuthToken, (state, payload) => ({
    ...state,
    authToken: payload
  }))