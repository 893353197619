import { middleware } from 'typescript-fsa-redux-middleware';

import { ContextActions, InteractiveActions, VideoActions } from '../actions';
import { RootState } from '../models';
import { ActionType, EvaluatedEmailTagSubAction, EvaluatedPhoneTagSubAction, EvaluatedUrlTagSubAction, EvaluatedVariableTagSubAction, TagSubActionType } from '@videosmart/player-template';
import {
    fullscreenServiceProviderRef,
} from '../../services';
import { bindActionCreators } from 'redux';
import { functionLibrary } from '../../utils';
import { functionCallServiceProviderRef } from '../../services/FunctionCallServiceProvider';

export const interactive = middleware<RootState>()
    .case(InteractiveActions.actionCreators.invoke, ({ dispatch }, next, action) => {
        switch (action.payload.type) {
            case ActionType.Play: {
                bindActionCreators(VideoActions.actionCreators.play, dispatch)();
                dispatch(VideoActions.actionCreators.play());
                break;
            }
            case ActionType.Pause: {
                bindActionCreators(VideoActions.actionCreators.pause, dispatch)();
                break;
            }
            case ActionType.Seek: {
                bindActionCreators(VideoActions.actionCreators.seek, dispatch)(action.payload.time);
                break;
            }
            case ActionType.RequestFullscreen: {
                // if(fullscreenServiceProviderRef.current) {
                //   fullscreenServiceProviderRef.current.requestFullscreen();
                // }
                break;
            }
            case ActionType.ExitFullscreen: {
                if (fullscreenServiceProviderRef.current) {
                    fullscreenServiceProviderRef.current.exitFullscreen();
                }
                break;
            }
            case ActionType.Tag: {
                if (!action.payload.subAction) {
                    break;
                }
                let subAction = action.payload.subAction;
                let target = undefined;
                let src = '';
                switch (action.payload.subAction.type) {
                    case TagSubActionType.Url: {
                        subAction = action.payload.subAction as EvaluatedUrlTagSubAction;
                        target = subAction?.target ? '_blank' : undefined;
                        src = subAction?.value;
                        break;
                    }
                    case TagSubActionType.Email: {
                        subAction = action.payload.subAction as EvaluatedEmailTagSubAction;
                        src = `mailto:${subAction?.value}`;
                        break;
                    }
                    case TagSubActionType.Phone: {
                        subAction = action.payload.subAction as EvaluatedPhoneTagSubAction;
                        src = `tel:${subAction?.value}`;
                        break;
                    }
                    case TagSubActionType.Variable: {
                        subAction = action.payload.subAction as EvaluatedVariableTagSubAction;
                        src = subAction?.value;
                        break;
                    }
                    default: {
                        break;
                    }
                }

                // Open window
                window.open(src, target);
                break;
            }
            case ActionType.LoadScene: {
                bindActionCreators(ContextActions.actionCreators.loadScene, dispatch)({
                    autoPlay: action.payload.autoPlay,
                    sceneId: action.payload.sceneId
                });
                break;
            }
            case ActionType.FunctionCall: {
                if (action.payload.callOnParent) {
                    if (functionCallServiceProviderRef.current) {
                        functionCallServiceProviderRef.current.callParentFunction(action.payload.function.name,
                            action.payload.function.args);
                    }
                    break;
                } else {
                    const fn = functionLibrary.find(action.payload.function.name);
                    const rootEl = (action.meta && action.meta.rootEl as HTMLDivElement) || undefined;
                    if (fn) {
                        fn.apply(rootEl, action.payload.function.args);
                    }
                }
                break;
            }
        }

        next(action);
    });