import { ErrorMessage } from '../models';

const mediaErrorMessages: { [id: number]: ErrorMessage } = {
  [MediaError.MEDIA_ERR_ABORTED]: {
    message: 'You aborted the media playback.',
    devMessage: 'MEDIA_ERR_ABORTED',
    allowReload: true
  },
  [MediaError.MEDIA_ERR_NETWORK]: {
    message: 'A network error caused the media download to fail part-way.',
    devMessage: 'MEDIA_ERR_NETWORK',
    allowReload: true
  },
  [MediaError.MEDIA_ERR_DECODE]: {
    message: 'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.',
    devMessage: 'MEDIA_ERR_DECODE',
    allowReload: true
  },
  [MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED]: {
    message: 'The media could not be loaded, either because the server or network failed or because the format is not supported.',
    devMessage: 'MEDIA_ERR_SRC_NOT_SUPPORTED',
    allowReload: true
  }
};

// commented for being obsolute
// if (MediaError.MS_MEDIA_ERR_ENCRYPTED) {
//   mediaErrorMessages[MediaError.MS_MEDIA_ERR_ENCRYPTED] = {
//     message: 'The media is encrypted and we do not have the keys to decrypt it.',
//     devMessage: 'MS_MEDIA_ERR_ENCRYPTED',
//     allowReload: false
//   };
// }

export const mediaErrorCreator = (error: MediaError): ErrorMessage => {
  const originalError = mediaErrorMessages[error.code];

  if(originalError) {
    const result = Object.assign({}, originalError);
    
    if(error.message) {
      result.devMessage = error.message;
    }

    return result;
  } else {
    return {
      message: "An error occurred. Please try again later.",
      allowReload: false
    };
  }
}