import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { throttle } from 'throttle-debounce';

import { withFunctionCallService } from '../hoc/withFunctionCallService';
import { RouteParams } from '../models';
import { ContextActions, EndpointActions, VideoActions } from '../redux/actions';
import { RootState } from '../redux/models';
import { selectIsFullscreen, selectRouteParams, selectSubtitleOptions } from '../redux/selectors';
import ControlBar from './ControlBar';
import DynamicStyle from './DynamicStyle';
import ErrorDisplay from './ErrorDisplay';
import LargePlayButton from './LargePlayButton';
import Loader from './Loader';
import styles from './Player.module.scss';
import Poster from './Poster';
import SubtitleDisplay from './SubtitleDisplay';
import PlayerVideoArea from './PlayerVideoArea';
import { EvaluatedSubtitleOptions } from '@videosmart/player-template/lib/models/evaluated/EvaluatedSubtitleOptions';
import { ISubtitleLanguage, selectAvailableSubtitleLanguages } from '../redux/selectors/selectAvailableSubtitleLanguages';
import {fullscreenServiceProviderRef} from "../services/FullscreenServiceProvider";
import { ContextMenu } from './ContextMenu';

export interface PlayerProps extends RouteParams {
  getPlayer: typeof EndpointActions.actionCreators.getPlayer;
  loadScene: typeof ContextActions.actionCreators.loadScene;
  pause: typeof VideoActions.actionCreators.pause;
  play: typeof VideoActions.actionCreators.play;
  seek: typeof VideoActions.actionCreators.seek;
  setVolume: typeof VideoActions.actionCreators.setVolume;
  userActive: typeof ContextActions.actionCreators.userActive;
  userInactive: typeof ContextActions.actionCreators.userInactive;
  currentTime: number;
  duration: number;
  hasError: boolean;
  isFullscreen: boolean;
  isMuted: boolean;
  isPlaying: boolean;
  isUserActive: boolean;
  volume: number;
  subtitleOptions: EvaluatedSubtitleOptions;
  setDefaultLanguage: typeof ContextActions.actionCreators.selectedSubtitleLanguage;
  avaibleSubtitles: ISubtitleLanguage[];
}

interface PlayerState {
    showContextMenu: boolean;
    contextMenuX: number;
    contextMenuY: number;
}

class Player extends Component<PlayerProps, PlayerState> {
  private _mouseIsDown: number = 0;

  private _touchEndTimeStamp: number = 0;

  private _userWasActive: boolean = false;

  private _throttledUserActive: throttle<() => void>;

  constructor(props: PlayerProps) {
    super(props);

      this._throttledUserActive = throttle(1000, true, () => { this.props.userActive({}); });

      this.state = {
          showContextMenu: false,
          contextMenuX: 0,
          contextMenuY: 0
      };
  }

  public componentDidMount = () => {
    const { allowTracking, playerId, recordId, getPlayer, loadScene, variables } = this.props;

    getPlayer({ allowTracking, playerId, recordId, variables }).then(() => loadScene());

      document.addEventListener('keydown', this.handleWindowKeyDown);
  }

  public componentDidUpdate  = (prevProps: any) => {
    const { subtitleOptions, setDefaultLanguage, avaibleSubtitles } = this.props;

    const foundNavigatedLanguage = avaibleSubtitles.find(subtitle => subtitle.languageCode && navigator.language && subtitle.languageCode.toLocaleLowerCase() ===  navigator.language.toLocaleLowerCase());
    
    const defaultLanguageIfNoNavigated = subtitleOptions.defaultLanguage;
 
    if (prevProps.avaibleSubtitles) {
      if (prevProps.avaibleSubtitles.length !== avaibleSubtitles.length) {

        if(subtitleOptions.defaultLanguage && foundNavigatedLanguage) {
          setDefaultLanguage(foundNavigatedLanguage.languageCode);
        } else if (defaultLanguageIfNoNavigated) {
          setDefaultLanguage(defaultLanguageIfNoNavigated);
        } else {
          setDefaultLanguage("off");
        }
      }
    }
  }

  public render = () => {
    const { hasError, isFullscreen } = this.props;
    const className = classNames(styles["root"], {
      [styles["large-screen-mode"]]: isFullscreen
    });
      
    return (
      <div
        id="player"
        className={className}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseOut={this.handleMouseOut}
        onMouseUp={this.handleMouseUp}
        onTouchEnd={this.handleTouchEnd}
        onTouchMove={this.handleTouchMove}
        onTouchStart={this.handleTouchStart}
        onContextMenu={this.handleContextMenu}
      >
        <DynamicStyle />
            {this.state.showContextMenu && (<ContextMenu top={this.state.contextMenuY} left={this.state.contextMenuX} />)}
        {hasError ?
          (
            <React.Fragment>
              <Poster />
              <ErrorDisplay />
            </React.Fragment>
          )
        :
          ( 
            <React.Fragment>
              <PlayerVideoArea 
                width={window.innerWidth} 
                height={window.innerHeight}
                isPlaying={this.props.isPlaying}
                pause={this.props.pause}
                play={this.props.play}
                />
              <Poster />
              <Loader />
              <LargePlayButton />
              <ControlBar />
              <SubtitleDisplay />
            </React.Fragment>
          )
      }
      </div>
    );
  }

  private handleClick = (event: React.MouseEvent) => {
    if (event.nativeEvent.isTouch) {
      if (!this.props.isUserActive) {
        this.props.userActive({
          timeout: 7000
        });
      } else {
        this.userInactive();
      }
      }
      this.toggleContextMenu(false);
    }
    private handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();

        //fixed height and width for context menu
        const contextMenuHeight = 84;
        const contextMenuWidth = 200;
        let xPosition = event.pageX;
        let yPosition = event.pageY;

        //validate click height and width to avoid context menu being displayed outside the window
        if (window.innerHeight - event.pageY < contextMenuHeight)
            yPosition = event.pageY - contextMenuHeight
        if (window.innerWidth - event.pageX < contextMenuWidth)
            xPosition = event.pageX - contextMenuWidth

        this.setState({ showContextMenu: true, contextMenuX: xPosition, contextMenuY: yPosition });
    }

  private handleMouseDown = (event: React.MouseEvent) => {
    if (this._touchEndTimeStamp !== event.timeStamp) {
      this.userActive();
      window.clearInterval(this._mouseIsDown);
      this._mouseIsDown = window.setInterval(() => this.userActive(), 250);
    }
  }

  private handleMouseMove = (event: React.MouseEvent) => {
    if (this._touchEndTimeStamp !== event.timeStamp) {
      this.userActive();
    }
  }

  private handleMouseOut = (event: React.MouseEvent) => {
    if (!event.relatedTarget) {
        this.userInactive();
        //hide context menu if mouse moves out player
        this.toggleContextMenu(false);
      }
      
  }

  private handleMouseUp = (event: React.MouseEvent) => {
    if (this._touchEndTimeStamp !== event.timeStamp) {
      this.userActive();
      window.clearInterval(this._mouseIsDown);
    }
  }

  private handleTouchEnd = (event: React.TouchEvent) => {
    this._touchEndTimeStamp = event.timeStamp;
  }

  private handleTouchMove = (event: React.TouchEvent) => {
    if (this._userWasActive) {
      this.userActive();
    }
  }

  private handleTouchStart = (event: React.TouchEvent) => {
    this._userWasActive = this.props.isUserActive;
  }

  private handleWindowKeyDown = (event: KeyboardEvent) => {
    // Key values at:
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
    switch (event.key) {
      // Jump back 5 seconds
      case "Left":
      case "ArrowLeft": {
        const { currentTime, seek } = this.props;
        seek(currentTime - 5);
        break;
      }
      // Jump forward 5 seconds
      case "Right":
      case "ArrowRight": {
        const { currentTime, seek } = this.props;
        seek(currentTime + 5);
        break;
      }
      // Volume up by 10%
      case "Up":
      case "ArrowUp": {
        const { setVolume } = this.props;
        const volume = this.props.volume + 0.1;
        setVolume({
          isMuted: volume <= 0,
          volume
        });
        break;
      }
      // Volume down by 10%
      case "Down":
      case "ArrowDown": {
        const { setVolume } = this.props;
        const volume = this.props.volume - 0.1;
        setVolume({
          isMuted: volume <= 0,
          volume
        });
        break;
      }
      // Play / Pause video
      case "Spacebar":
      case " ": {
        const { isPlaying, pause, play } = this.props;
        if (isPlaying) {
          pause();
        } else {
          play();
        }
        break;
      }
      // Toggle Fullscreen
      case "f":
      case "F": {
       fullscreenServiceProviderRef.current?.toggleFullscreen()
        break;
      }
      // Toggle Mute
      case "m":
      case "M": {
        const { isMuted, setVolume, volume } = this.props;

        setVolume({
          isMuted: !isMuted,
          volume: volume < 0.4 ? 0.4 : undefined
        });

        break;
      }
      // Jump to percentage
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9": {
        const { duration, seek } = this.props;
        const percentage = parseInt(event.key);
        seek(duration * (percentage / 10));
        break;
        }
        case "Escape": {
            this.toggleContextMenu(false);
            break;
        }
      default: {
        // Key is not used by player.
        return;
      }
    }

    this.userActive();
    event.stopPropagation();
    event.preventDefault();
  }

  private userActive = () => {
    if (this.props.isUserActive) {
      this._throttledUserActive();
    } else {
      this.props.userActive({});
    }
  }

  private userInactive = () => {
    this.props.userInactive();
    }
    private toggleContextMenu = (show: boolean) => {
        if (this.state.showContextMenu !== show) {
            this.setState({ showContextMenu: show });
        }
    }
}

const mapStateToProps = (state: RootState): Pick<PlayerProps, 'allowTracking' | 'currentTime' | 'duration' | 'hasError' | 'isFullscreen' | 'isMuted' | 'isPlaying' | 'isUserActive' | 'playerId' | 'volume' | 'subtitleOptions' | 'avaibleSubtitles' | 'variables'> => ({
  ...selectRouteParams(state),
  currentTime: state.video.currentTime,
  duration: state.video.duration,
  hasError: state.error.hasError,
  isMuted: state.video.isMuted,
  isPlaying: state.video.isPlaying,
  isFullscreen: selectIsFullscreen(state),
  isUserActive: state.context.isUserActive,
  volume: state.video.volume,
  subtitleOptions: selectSubtitleOptions(state),
  avaibleSubtitles: selectAvailableSubtitleLanguages(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<PlayerProps, 'getPlayer' | 'loadScene' | 'pause' | 'play' | 'seek' | 'setVolume' | 'userActive' | 'userInactive' | 'setDefaultLanguage'> => ({
  getPlayer: bindActionCreators(EndpointActions.actionCreators.getPlayer, dispatch),
  loadScene: bindActionCreators(ContextActions.actionCreators.loadScene, dispatch),
  pause: bindActionCreators(VideoActions.actionCreators.pause, dispatch),
  play: bindActionCreators(VideoActions.actionCreators.play, dispatch),
  seek: bindActionCreators(VideoActions.actionCreators.seek, dispatch),
  setVolume: bindActionCreators(VideoActions.actionCreators.setVolume, dispatch),
  userActive: bindActionCreators(ContextActions.actionCreators.userActive, dispatch),
  userInactive: bindActionCreators(ContextActions.actionCreators.userInactive, dispatch),
  setDefaultLanguage: bindActionCreators(ContextActions.actionCreators.selectedSubtitleLanguage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, { forwardRef: true })(withFunctionCallService(Player));

