import { Pause, PlayArrow, Replay } from '@mui/icons-material';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { VideoActions } from '../redux/actions';
import { RootState } from '../redux/models';
import ButtonBase from './ButtonBase';
import styles from './PlayToggle.module.scss';

export interface PlayToggleProps {
  pause: typeof VideoActions.actionCreators.pause;
  play: typeof VideoActions.actionCreators.play;
  isEnded: boolean;
  isPlaying: boolean;
}

class PlayToggle extends React.Component<PlayToggleProps> {
  public render = () => {
    const { isEnded, isPlaying } = this.props;

    return (
      <ButtonBase 
        className={styles["root"]}
        onClick={this.handleClick}
      >
        {isEnded ? <Replay /> : isPlaying ? <Pause /> : <PlayArrow />}
      </ButtonBase>
    );
  }

  private handleClick = () => {
    const { isEnded, isPlaying, pause, play } = this.props;

    if(isEnded || !isPlaying) {
      play();
    } else {
      pause();
    }
  }
}

const mapStateToProps = (state: RootState): Pick<PlayToggleProps, 'isEnded' | 'isPlaying'> => ({
  isEnded: state.video.isEnded,
  isPlaying: state.video.isPlaying
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<PlayToggleProps, 'pause' | 'play'> => ({
  pause: bindActionCreators(VideoActions.actionCreators.pause, dispatch),
  play: bindActionCreators(VideoActions.actionCreators.play, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayToggle);
