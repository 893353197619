import { ErrorOutline } from '@mui/icons-material';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import { Environment } from '../enums';
import { RootState } from '../redux/models';
import { selectEnvironment } from '../redux/selectors/selectEnvironment';
import ButtonBase from './ButtonBase';
import { classes } from './DynamicStyle';
import styles from './ErrorDisplay.module.scss';

export interface ErrorDisplayProps {
  allowReload: boolean;
  devMessage?: string;
  environment: Environment;
  message: string;
}

class ErrorDisplay extends React.Component<ErrorDisplayProps> {
  public render = () => {
    const { allowReload, devMessage, environment, message } = this.props;
  
    const rootClassName = classNames(styles["root"], classes.background.backgroundColor);
    const modalClassName = classNames(styles["modal"], classes.background.backgroundColor, classes.highlight.borderColor);
    const iconClassName = classNames(styles["modal-icon"], classes.accent.color);
    const reloadClassName = classNames(styles["modal-reload"], classes.highlight.borderColor);
  
    return (
      <div className={rootClassName}>
        <div className={modalClassName}>
          <div className={styles["modal-message"]}>
            <div className={iconClassName}>
              <ErrorOutline />
            </div>
            <div className={styles["modal-text"]}>
              <span>{message}</span>
              {(environment === Environment.Development && devMessage) && (
                <div className={styles["modal-description"]}>
                  {devMessage}
                </div>
              )}
            </div>
          </div>
          {allowReload && (
            <ButtonBase
              className={reloadClassName}
              onClick={this.handleReload}
            >
              <span>Reload video</span>
            </ButtonBase>
          )}
        </div>
      </div>
    );
  }

  private handleReload = () => {
    window.location.reload();
  }
}

const mapStateToProps = (state: RootState): Pick<ErrorDisplayProps, 'allowReload' | 'devMessage' | 'environment' | 'message'> => ({
  allowReload: state.error.allowReload,
  devMessage: state.error.devMessage,
  environment: selectEnvironment(state),
  message: state.error.message,
});

export default connect(mapStateToProps)(ErrorDisplay);
