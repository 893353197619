import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/models';
import { selectBufferedTime } from '../redux/selectors';
import { classes } from './DynamicStyle';
import styles from './LoadProgress.module.scss';
import Slider from './Slider';

export interface LoadProgressProps {
  bufferedTime: number;
}

const LoadProgress: React.FC<LoadProgressProps> = (props: LoadProgressProps) => {
  const { bufferedTime } = props;

  const className = classNames(styles["root"], classes.highlight.backgroundColor);

  return (
    <Slider
      className={className}
      value={bufferedTime}
    />
  );
}

const mapStateToProps = (state: RootState): Pick<LoadProgressProps, 'bufferedTime'> => ({
  bufferedTime: selectBufferedTime(state)
});

export default connect(mapStateToProps)(LoadProgress);
