import { Color } from "@videosmart/player-template";
import { colorToHsl } from "./colorToHsl";

export const calculateShadowColor = (backgroundColor: Color): Color => {
  if (backgroundColor.alpha < 0.2 || colorToHsl(backgroundColor)[2] < 0.5) {
    return {
      red: 0,
      green: 0,
      blue: 0,
      alpha: 0.6
    };
  } else {
    return {
      red: 0,
      green: 0,
      blue: 0,
      alpha: 0.3
    };
  }
}