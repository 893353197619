import { actionCreatorFactory } from 'typescript-fsa';
import { asyncFactory, thunkToAction } from 'typescript-fsa-redux-thunk';
import {  PlayerError, WithStatus } from '../../models';
import { GetPlayerForWatchResponse, SignAssetResponse } from '../../models/responses';
import { RootState } from '../models';
import { getPlayerWorker, logErrorWorker, postEventsWorker, signAssetWorker } from './asyncWorkers';

const apiRoot = '/api';
enum Type {
  GetPlayer = 'GET_PLAYER',
  LogMediaError = 'LOG_MEDIA_ERROR',
  LogPlayerError = 'LOG_PLAYER_ERROR',
  PostEvents = 'POST_EVENTS',
  SignAsset = 'SIGN_ASSET'
}

const create = actionCreatorFactory('ENDPOINT');
const createAsync = asyncFactory<RootState>(create);

// Async Action Creators
export const asyncActionCreators = {
  getPlayerCreator: createAsync<any,WithStatus<GetPlayerForWatchResponse>>(Type.GetPlayer, getPlayerWorker(apiRoot)),
  logMediaErrorCreator: createAsync<any,WithStatus<{}>>(Type.LogMediaError, logErrorWorker<MediaError>(apiRoot)),
  logPlayerErrorCreator: createAsync(Type.LogPlayerError, logErrorWorker<PlayerError>(apiRoot)),
  postEventsCreator: createAsync<any,WithStatus<{}>>(Type.PostEvents, postEventsWorker(apiRoot)),
  signAssetCreator: createAsync<any, WithStatus<SignAssetResponse>>(Type.SignAsset, signAssetWorker(apiRoot))
};

// Action Creators
export const actionCreators = {
  getPlayer: thunkToAction(asyncActionCreators.getPlayerCreator.action),
  logMediaError: thunkToAction(asyncActionCreators.logMediaErrorCreator.action),
  //@ts-ignore
  logPlayerError: thunkToAction(asyncActionCreators.logPlayerErrorCreator.action),
  postEvents: thunkToAction(asyncActionCreators.postEventsCreator.action),
  signAsset: thunkToAction(asyncActionCreators.signAssetCreator.action),
};