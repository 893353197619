import { EvaluatedScene } from '@videosmart/player-template';
import { createSelector } from 'reselect';

import { RootState } from '../models';
import { selectCurrentSceneId } from './selectCurrentSceneId';
import { selectEvaluatedTemplate } from './selectEvaluatedTemplate';
import { hasValue } from '../../utils';

export const selectCurrentScene = createSelector(
  (state:RootState) => selectCurrentSceneId(state),
  (state:RootState) => {
    const template = selectEvaluatedTemplate(state);
    if(template) {
      return template.scenes;
    }
  },
  (currentSceneIndex:number | undefined, scenes:EvaluatedScene[] | undefined) => {
    if(hasValue(currentSceneIndex) && scenes) {
      return scenes[currentSceneIndex];
    }
  }
);