import { VolumeDown, VolumeMute, VolumeOff, VolumeUp } from '@mui/icons-material';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { VideoActions } from '../redux/actions';
import { RootState } from '../redux/models';
import ButtonBase from './ButtonBase';

export interface MuteToggleProps {
  setVolume: typeof VideoActions.actionCreators.setVolume;
  className?: string;
  isMuted: boolean;
  volume: number;
}

class MuteToggle extends React.Component<MuteToggleProps> {
  public render = () => {
    const { className, isMuted, volume } = this.props;

    return (
      <ButtonBase
        className={className}
        onClick={this.handleClick}
      >
        {
          (isMuted || volume === 0) ? <VolumeOff /> :
          (volume <= 0.33) ? <VolumeMute /> :
          (volume <= 0.67) ? <VolumeDown /> :
          <VolumeUp />
        }
      </ButtonBase>
    );
  }

  private handleClick = () => {
    const { isMuted, setVolume } = this.props;
    
    setVolume({
      isMuted: !isMuted
    });
  }
}

const mapStateToProps = (state: RootState): Pick<MuteToggleProps, 'isMuted' | 'volume'> => ({
  isMuted: state.video.isMuted,
  volume: state.video.volume
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<MuteToggleProps, 'setVolume'> => ({
  setVolume: bindActionCreators(VideoActions.actionCreators.setVolume, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MuteToggle);
