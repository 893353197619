import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fromEvent, Subscription } from 'rxjs';
import { throttle } from 'throttle-debounce';

import { ContextActions } from '../redux/actions';
import { onWindowResize } from '../utils';
import { classes } from './DynamicStyle';
import styles from './Slider.module.scss';
import progressBarStyles from './ProgressBar.module.scss';

export interface SliderProps {
  changeOnHover?: boolean;
  className?: string;
  draggable?: boolean;
  hitBoxEl?: HTMLElement;
  onDragStart?: (value: number) => void;
  onDragEnd?: (value: number) => void;
  onValueChange?: (value: number) => void;
  readonly?: boolean;
  shyScrubber?: boolean;
  userActive: typeof ContextActions.actionCreators.userActive;
  value: number;
}

interface SliderState {
  isScrubbing: boolean;
  value: number;
}

class Slider extends React.Component<SliderProps, SliderState> {
  private _el: React.RefObject<HTMLDivElement>;

  private _hitBoxEl?: HTMLElement;

  private _scrubbingSubscriptions: Subscription[] = [];

  private _sliderConteinerRef: React.RefObject<HTMLDivElement>;

  private _hitBoxSubscriptions: Subscription[] = [];

  private _subscriptions: Subscription[] = [];
  
  private _throttledUserActive: throttle<() => void>;

  private _touchIdentifier?: number = undefined;

  constructor(props: SliderProps) {
    super(props);

    this._el = React.createRef();
    this._sliderConteinerRef = React.createRef();

    this.state = {
      isScrubbing: false,
      value: props.value
    }
    
    this._throttledUserActive = throttle(1000, true, () => { this.props.userActive({}); });
  }

  private get value(): number {
    return this.state.isScrubbing ? this.state.value : this.props.value;
  }

  public componentDidMount = () => {
    if (this.props.draggable || this.props.changeOnHover) {
      if (this.props.draggable) {
        this._subscriptions.push(onWindowResize.subscribe(() => { this.forceUpdate(); }));
      }
      this.registerHitBox();
      this.forceUpdate();
    }
  }

  public componentDidUpdate = (prevProps: SliderProps) => {
    if (this.props.hitBoxEl !== prevProps.hitBoxEl) {
      this.registerHitBox();
    }
  }

  public componentWillUnmount = () => {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public render = () => {
    const { className, draggable, shyScrubber } = this.props;

    const sliderClassName = classNames(styles["slider"], className, {
      [classes.accent.backgroundColor]: draggable,
    });

    const value = this.value;

    const SliderEl = (props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
      <div
        {...props}
        ref={this._el}
        className={sliderClassName}
        style={{
          transform: `scaleX(${value})`
        }}
      />
    );

    if (draggable) {
      const sliderContainerClassName = classNames(styles["slider-container"], {
        [styles["scrubbing"]]: this.state.isScrubbing
      });
      const scrubberContainerPosition = this.calculateScrubberPosition(value);
      const scrubberClassName = classNames(classes.boxShadow, classes.accent.backgroundColor, shyScrubber ? [
        styles["shy-scrubber-button"],
        progressBarStyles["shy-scrubber-button"]
      ] : styles["scrubber-button"]);

      return (
        <div
          className={sliderContainerClassName}
          ref={this._sliderConteinerRef}
        >
          <SliderEl />
          <div
            className={styles["scrubber-container"]}
            style={{
              transform: `translateX(${scrubberContainerPosition}em)`
            }}
          >
            <div className={scrubberClassName} />
          </div>
        </div>
      );
    } else {
      return (
        <SliderEl />
      );
    }
  }

  private calculateScrubberPosition = (value: number): number => {
    if (this._el.current && this.props.draggable) {
      const computedStyle = getComputedStyle(this._el.current);
      const scrubberPosition = this._el.current.offsetWidth * value / parseFloat(computedStyle.fontSize || "0");

      return scrubberPosition;
    } else {
      return 0;
    }
  }

  private getTouchFromEvent = (event: TouchEvent) => {
    const touches = event.changedTouches;

    for (let i = 0; i < touches.length; i++) {
      if (touches[i].identifier === this._touchIdentifier) {
        return touches[i];
      }
    }

    return undefined;
  }

  private getValueFromX = (x: number): number => {
    if (this._el.current) {
      const targetEl = this._el.current.parentElement || this._el.current;
      const rect = targetEl.getBoundingClientRect();
      const value = (x - rect.left) / rect.width;
      return value;
    } else {
      return 0;
    }
  }

  private handleDragEnd = () => {
    const { onDragEnd } = this.props;

    this.unsubscribeScrubbingSubscriptions();

    this._touchIdentifier = undefined;

    this.userActive();

    if (onDragEnd) {
      onDragEnd(this.state.value);
    }

    this.setState(() => ({
      isScrubbing: false
    }));
  }

  private handleDragChange = (value: number) => {
    this.userActive();
    const normalizedValue = Math.min(1, Math.max(0, value));
    if (this.props.onValueChange) {
      this.props.onValueChange(normalizedValue);
    }
    this.setState(() => ({ value: normalizedValue }));
  }

  private handleDragStart = (event: MouseEvent | TouchEvent) => {
    const { onDragStart } = this.props;

    event.stopPropagation();

    this.userActive();

    if (onDragStart) {
      onDragStart(this.props.value);
    }

    this.setState(() => ({
      isScrubbing: true,
      value: this.props.value
    }));
  }

  private handleMouseDown = (event: MouseEvent) => {
    this.handleDragStart(event);

    const onDocumentMouseMove = fromEvent<MouseEvent>(document, 'mousemove');
    const onDocumentMouseUp = fromEvent<MouseEvent>(document, 'mouseup');

    this._scrubbingSubscriptions.push(onDocumentMouseMove.subscribe(this.handleMouseMove));
    this._scrubbingSubscriptions.push(onDocumentMouseUp.subscribe(this.handleMouseUp));

    this.handleMouseMove(event);
  }

  private handleMouseMove = (event: MouseEvent) => {
    const value = this.getValueFromX(event.clientX);
    this.handleDragChange(value);
  }

  private handleMouseUp = (event: MouseEvent) => {
    this.handleDragEnd();
  }

  private handleTouchEnd = (event: TouchEvent) => {
    const touch = this.getTouchFromEvent(event);

    if (touch) {
      this.handleDragEnd();
    }
  }

  private handleTouchMove = (event: TouchEvent) => {
    const touch = this.getTouchFromEvent(event);

    if (touch) {
      const value = this.getValueFromX(touch.clientX);
      this.handleDragChange(value);
    }
  }

  private handleTouchStart = (event: TouchEvent) => {
    this.handleDragStart(event);

    if (this._touchIdentifier != null) {
      this.unsubscribeScrubbingSubscriptions();
    }

    const onDocumentTouchMove = fromEvent<TouchEvent>(document, 'touchmove', { passive: true });
    const onDocumentTouchEnd = fromEvent<TouchEvent>(document, 'touchend');

    this._scrubbingSubscriptions.push(onDocumentTouchMove.subscribe(this.handleTouchMove));
    this._scrubbingSubscriptions.push(onDocumentTouchEnd.subscribe(this.handleTouchEnd));

    this._touchIdentifier = event.changedTouches[0].identifier;

    this.handleTouchMove(event);
  }

  private registerHitBox = () => {
    for (let sub of this._hitBoxSubscriptions) {
      sub.unsubscribe();
    }
    this._hitBoxSubscriptions = [];

    this._hitBoxEl = this.props.hitBoxEl
      || (this._sliderConteinerRef && this._sliderConteinerRef.current)
      || (this._el && this._el.current)
      || undefined;

    if (this._hitBoxEl) {
      if (this.props.changeOnHover) {
        this._hitBoxSubscriptions.push(fromEvent<MouseEvent>(this._hitBoxEl, 'mousemove').subscribe(this.handleMouseMove));
        this._hitBoxSubscriptions.push(fromEvent<TouchEvent>(this._hitBoxEl, 'touchmove', { passive: true }).subscribe(this.handleTouchMove));
      } else {
        this._hitBoxSubscriptions.push(fromEvent<MouseEvent>(this._hitBoxEl, 'mousedown').subscribe(this.handleMouseDown));
        this._hitBoxSubscriptions.push(fromEvent<TouchEvent>(this._hitBoxEl, 'touchstart', { passive: true }).subscribe(this.handleTouchStart));
      }
    }
  }

  private unsubscribeScrubbingSubscriptions = () => {
    for (let sub of this._scrubbingSubscriptions) {
      sub.unsubscribe();
    }
    this._scrubbingSubscriptions = []
  }

  private userActive = () => {
    this._throttledUserActive();
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Pick<SliderProps, 'userActive'> => ({
  userActive: bindActionCreators(ContextActions.actionCreators.userActive, dispatch),
});

export default connect(undefined, mapDispatchToProps)(Slider);
