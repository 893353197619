import * as React from 'react';
import classNames from 'classnames';
import HoverProgress from './HoverProgress';
import LoadProgress from './LoadProgress';
import PlayProgress from './PlayProgress';
import styles from './ProgressBar.module.scss';
import { RootState } from '../redux/models';
import { connect } from 'react-redux';
import { classes } from './DynamicStyle';

export interface ProgressBarProps {
  isMobile: boolean;
}

interface ProgressBarState {
  hitBoxEl?: HTMLDivElement;
}

class ProgressBar extends React.Component<ProgressBarProps, ProgressBarState> {
  constructor(props: ProgressBarProps) {
    super(props);

    this.state = {
      hitBoxEl: undefined
    };
  }

  public render = () => {
    const { isMobile } = this.props;
    const { hitBoxEl } = this.state;

    const rootClassName = classNames(styles["root"], {
      [styles["mobile"]]: isMobile
    });
    const listClassName = classNames(styles["list"], {[classes.progressBar.borderLeftColor]:isMobile}
    );

    return (
      <div
        className={rootClassName}
        ref={this.registerHitBoxEl}
      >
        <div className={styles["padding"]} />
        <div className={listClassName}>
          <LoadProgress />
          <HoverProgress hitBoxEl={hitBoxEl} />
          <PlayProgress hitBoxEl={hitBoxEl} />
        </div>
      </div>
    );
  }

  private registerHitBoxEl = (el: HTMLDivElement | null) => {
    if(!this.state.hitBoxEl && el) {
      this.setState(() => ({
        hitBoxEl: el
      }));
    }
  }
}

const mapStateToProps = (state: RootState): Pick<ProgressBarProps, 'isMobile'> => ({
  isMobile: state.context.isMobile
});

export default connect(mapStateToProps)(ProgressBar);
