declare global {
  interface MouseEvent {
    isTouch: boolean;
  }
}

export const checkForTouches = () => {
  let timeStamp: number = 0;
  
  window.addEventListener('click', (e) => { e.isTouch = (e.timeStamp === timeStamp); }, true);
  window.addEventListener('touchend', (e) => { timeStamp = e.timeStamp; }, true);
}