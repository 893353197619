import classNames from 'classnames';
import * as React from 'react';

import { classes } from './DynamicStyle';
import styles from './VolumeControls.module.scss';
import MuteToggle from './MuteToggle';
import VolumeSlider from './VolumeSlider';

export interface VolumeControlsProps {
}

interface VolumeControlsState {
  hitBoxEl?: HTMLDivElement;
}

class VolumeControls extends React.Component<VolumeControlsProps, VolumeControlsState> {
  constructor(props: VolumeControlsProps) {
    super(props);

    this.state = {
      hitBoxEl: undefined
    };
  }

  public render = () => {
    const { hitBoxEl } = this.state;

    const containerClassName = classNames(styles["container"], classes.highlight.backgroundColor);

    return (
      <div className={styles["root"]}>
        <MuteToggle className={styles["mute-toggle"]} />
        <div
          className={styles["padding"]}
          ref={this.registerHitBoxEl}
        >
          <div className={containerClassName}>
            <VolumeSlider hitBoxEl={hitBoxEl} />
          </div>
        </div>
      </div>
    );
  }
  
  private registerHitBoxEl = (el: HTMLDivElement | null) => {
    if(!this.state.hitBoxEl && el) {
      this.setState(() => ({
        hitBoxEl: el
      }));
    }
  }
}

export default VolumeControls;
