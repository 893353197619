const getDate = (date: Date): string => {
  const years = date.getFullYear().toString();
  const months = (date.getMonth() + 1).toString().padStart(2, "0");
  const days = date.getDate().toString().padStart(2, "0");
  
  return `${years}-${months}-${days}`;
}

const getTime = (date: Date): string => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const milliseconds = date.getMilliseconds().toString().padStart(3, "0");
  
  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}

const getOffset = (date: Date): string => {
  const offset = date.getTimezoneOffset();

  const offsetPrefix = (offset <= 0) ? "+" : "-";
  const offsetHours = Math.floor(Math.abs(offset) / 60).toString().padStart(2, "0");
  const offsetMinutes = (Math.abs(offset) % 60).toString().padStart(2, "0");
  
  return `${offsetPrefix}${offsetHours}:${offsetMinutes}`;
}

export const getDateTimeOffset = (dateUtc?: Date): string => {
  // ISO 8601 "2018-05-04T04:03:30.123+00:00"
  // https://en.wikipedia.org/wiki/ISO_8601 (with milliseconds)

    const nowUtcTimestamp = Date.now();
    const dateTime = dateUtc || new Date(nowUtcTimestamp);
  return `${getDate(dateTime)}T${getTime(dateTime)}${getOffset(dateTime)}`;
}