import { Color } from "@videosmart/player-template";

export const colorToHsl = (color: Color): number[] => {
  // Source: https://gist.github.com/mjackson/5311256
  let r = color.red / 255;
  let g = color.green / 255;
  let b = color.blue / 255;

  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
      default: h = 0; break;
    }

    h /= 6;
  }

  return [h, s, l];
}