import {
    templateConverter,
    TemplateMessage,
    variableDictionaryConverter,
    VariableDictionaryMessage,
} from '@videosmart/player-template';
import { AsyncWorker } from 'typescript-fsa-redux-thunk';

import { EndpointActions } from '..';
import { Environment, ErrorType } from '../../../enums';
import { WithStatus } from '../../../models';
import { GetPlayerForWatchRequest } from '../../../models/requests';
import { GetPlayerForWatchResponse } from '../../../models/responses';
import { withStatus } from '../../../utils';
import { RootState } from '../../models';

interface RawGetPlayerForWatchResponse {
    environment: Environment;
    playerId: string;
    sessionId: string;
    template: TemplateMessage;
    variables: VariableDictionaryMessage;
    collectAnalytics: boolean;
};

export const getPlayerWorker = (apiRoot: string): AsyncWorker<GetPlayerForWatchRequest, WithStatus<GetPlayerForWatchResponse>, RootState> => async (params, dispatch) => {
    let url;
    let hasQueryParameters = false;
    if(params.playerId){
        url = `${apiRoot}/players/${params.playerId}/watch`;
        if (params.playerId.length <= 15) {
            url += "/short"
        }
    }
    else if(params.recordId){
        url = `${apiRoot}/players/${params.recordId}/watch/recordId`;
    }

    //if no valid url throw error
    if(!url){
        dispatch(EndpointActions.asyncActionCreators.logPlayerErrorCreator.action({ type: ErrorType.PlayerNotFound }));
        throw new Error('Failed to load user data!');
    }

    if (!params.allowTracking) {
        url += '/?t=0';
        hasQueryParameters = true;
    }

    if (params.variables) {
        url = hasQueryParameters ? url + `&variables=${encodeURIComponent(params.variables)}` : url + `?variables=${encodeURIComponent(params.variables)}`;
        hasQueryParameters = true;
    }

    const response = await fetch(url, {
        method: 'GET'
    });

    if (!response.ok) {
        switch (response.status) {
            case 404: {
                dispatch(EndpointActions.asyncActionCreators.logPlayerErrorCreator.action({ type: ErrorType.PlayerNotFound }));
                break;
            }
        }
        throw new Error('Failed to load user data!');
    }

    const responseText = await response.text();
    const responseBodyRaw: RawGetPlayerForWatchResponse = JSON.parse(responseText);   
    try {
        const responseBody: GetPlayerForWatchResponse = {
            environment: responseBodyRaw.environment,
            playerId: responseBodyRaw.playerId,
            sessionId: responseBodyRaw.sessionId,
            template: templateConverter.fromMessage(responseBodyRaw.template),
            variables: variableDictionaryConverter.fromMessage(responseBodyRaw.variables),
            collectAnalytics: responseBodyRaw.collectAnalytics
        };

        return withStatus({
            ok: response.ok,
            status: response.status
        })(responseBody);
    }
    catch (err) {
        console.log(err);
        dispatch(EndpointActions.asyncActionCreators.logPlayerErrorCreator.action({ type: ErrorType.Unknown }));
        throw new Error('Failed to load user data!');
    }
};