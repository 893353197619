import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { throttle } from 'throttle-debounce';

import { withFrameUpdates } from '../hoc';
import { FrameUpdateEvent } from '../models';
import { VideoActions } from '../redux/actions';
import { RootState } from '../redux/models';
import Slider from './Slider';

export interface PlayProgressProps extends FrameUpdateEvent {
  duration: number;
  hitBoxEl?: HTMLElement;
  isEnded: boolean;
  isPlaying: boolean;
  pause: typeof VideoActions.actionCreators.pause;
  play: typeof VideoActions.actionCreators.play;
  seek: typeof VideoActions.actionCreators.seek;
}

class PlayProgress extends React.Component<PlayProgressProps> {
  private _throttledSeek: throttle<typeof VideoActions.actionCreators.seek>;
  
  private _videoWasPlaying: boolean = false;
  
  constructor(props: PlayProgressProps) {
    super(props);

    this._throttledSeek = throttle(250, true, this.props.seek);
  }

  public render = () => {
    const { currentTime, duration, hitBoxEl } = this.props;

    const value = currentTime / duration;

    return (
      <Slider
        draggable={true}
        hitBoxEl={hitBoxEl}
        shyScrubber={true}
        value={value}
        onDragStart={this.handleDragStart}
        onDragEnd={this.handleDragEnd}
        onValueChange={this.handleValueChange}
      />
    );
  }

  private handleDragEnd = (value: number) => {
    if (this._videoWasPlaying && !this.props.isEnded) {
      this.props.play();
    }
    this.props.seek(value * this.props.duration);
  }

  private handleDragStart = () => {
    this._videoWasPlaying = this.props.isPlaying;
    this.props.pause();
  }

  private handleValueChange = (value: number) => {
    this._throttledSeek(value * this.props.duration);
  }
}

const mapStateToProps = (state: RootState): Pick<PlayProgressProps, 'duration' | 'isEnded' | 'isPlaying'> => ({
  duration: state.video.duration,
  isEnded: state.video.isEnded,
  isPlaying: state.video.isPlaying
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<PlayProgressProps, 'pause' | 'play' | 'seek'> => ({
  pause: bindActionCreators(VideoActions.actionCreators.pause, dispatch),
  play: bindActionCreators(VideoActions.actionCreators.play, dispatch),
  seek: bindActionCreators(VideoActions.actionCreators.seek, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withFrameUpdates(PlayProgress));
