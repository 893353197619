import { EvaluatedStyle, Style } from '@videosmart/player-template';
import { createSelector } from 'reselect';

import { calculateHighlightColor, calculateShadowColor } from '../../utils';
import { RootState } from '../models';
import { selectEvaluatedTemplate } from './selectEvaluatedTemplate';

const defaultColors: Pick<Required<Style>, 'foregroundColor' | 'accentColor' | 'backgroundColor'> = {
  foregroundColor: {
    red: 255,
    green: 255,
    blue: 255,
    alpha: 1
  },
  accentColor: {
    red: 50,
    green: 185,
    blue: 230,
    alpha: 1
  },
  backgroundColor: {
    red: 0,
    green: 0,
    blue: 0,
    alpha: 0.834
  }
};

const defaultStyle: Required<Style> = {
  ...defaultColors,
  highlightColor: calculateHighlightColor(defaultColors.backgroundColor),
  shadowColor: calculateShadowColor(defaultColors.backgroundColor)
}

export const selectCalculatedStyle = createSelector(
  (state: RootState) => {
    const template = selectEvaluatedTemplate(state);
    if (template) {
      return template.style;
    }
  },
  (style: EvaluatedStyle | undefined) => {
    if (style) {
      return {
        foregroundColor: style.foregroundColor || defaultStyle.foregroundColor,
        accentColor: style.accentColor || defaultStyle.accentColor,
        backgroundColor: style.backgroundColor || defaultStyle.backgroundColor,
        highlightColor: style.highlightColor || (style.backgroundColor ? calculateHighlightColor(style.backgroundColor) : defaultStyle.highlightColor),
        shadowColor: style.shadowColor || (style.backgroundColor ? calculateShadowColor(style.backgroundColor) : defaultStyle.shadowColor)
      };
    } else {
      return defaultStyle;
    }
  }
);