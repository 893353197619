import { middleware } from 'typescript-fsa-redux-middleware';

import { videoRef } from '../../components/Video';
import { VideoActions, EndpointActions, ContextActions } from '../actions';
import { bindActionCreators } from 'redux';
import { hasValue } from '../../utils';
import { RootState } from '../models';

export const video = middleware<RootState>()
  .case(ContextActions.asyncActionCreators.loadSceneCreator.async.done, (api, next, action:any) => {
    const { dispatch, getState } = api;

    const { params } = action.payload;

    if (params && hasValue(params.autoPlay)) {
      const state = getState();
      if (state.video.isPlaying && !params.autoPlay) {
        bindActionCreators(VideoActions.actionCreators.pause, dispatch)();
      } else {
        bindActionCreators(VideoActions.actionCreators.play, dispatch)();
      }
    }

    next(action);
  })
  .case(EndpointActions.asyncActionCreators.getPlayerCreator.async.done, (api, next, action) => {
    const { dispatch } = api;

    if (action.payload.result.template.autoPlay !== false) {
      bindActionCreators(VideoActions.actionCreators.play, dispatch)();
    }

    next(action);
  })
  .case(VideoActions.actionCreators.pause, (api, next, action) => {
    if (videoRef.current) {
      videoRef.current.pause();
    }

    next(action);
  })
  .case(VideoActions.actionCreators.play, (api, next, action) => {
    if (videoRef.current) {
      videoRef.current.play();
    }

    next(action);
  })
  .case(VideoActions.actionCreators.seek, (api, next, action) => {
    // Normalize payload
    const { duration } = api.getState().video;
    action.payload = Math.max(0, Math.min(duration, action.payload));

    if (videoRef.current) {  
      videoRef.current.seekTo(action.payload);
    }

    next(action);
  });