import { Color, Style } from '@videosmart/player-template';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { RootState } from '../redux/models';
import { selectCalculatedStyle } from '../redux/selectors';
import { colorToRgbString, colorToString, escapeClassnames } from '../utils';
import styles from './DynamicStyle.module.scss';
import loaderStyles from './Loader.module.scss';

export const classes = {
  foreground: {
    backgroundColor: styles["background-color-1"],
    color: styles["color-1"],
    fill: styles["fill-1"]
  },
  accent: {
    backgroundColor: styles["background-color-2"],
    color: styles["color-2"],
    fill: styles["fill-2"]
  },
  background: {
    backgroundColor: styles["background-color-3"],
    color: styles["color-3"],
    fill: styles["fill-3"]
  },
  highlight: {
    backgroundColor: styles["background-color-4"],
    color: styles["color-4"],
    fill: styles["fill-4"],
    hoverBackgroundColor: styles["hover-background-color-4"],
    borderColor: styles["border-color-4"]
  },
  textShadow: styles["text-shadow"],
  boxShadow: styles["box-shadow"],
  loader: loaderStyles["root"],
  largePlayButton: 'placeholder',
  progressBar:{
    borderLeftColor: styles["border-left-color"]
  }
};

export interface DynamicStyleProps extends Required<Style> {
}

const DynamicStyle: React.FC<DynamicStyleProps> = (props: DynamicStyleProps) => {
  const { foregroundColor, accentColor, backgroundColor, highlightColor, shadowColor } = props;

  const largePlayButtonBackground: Color = {
    ...foregroundColor,
    alpha: 0.2
  }

  return ReactDOM.createPortal((
    <style type={"text/css"}>
      {/* Foreground */}
      {`.${escapeClassnames(classes.foreground.backgroundColor)} { background-color: ${colorToString(foregroundColor)} }`}
      {`.${escapeClassnames(classes.foreground.color)} { color: ${colorToString(foregroundColor)} }`}
      {`.${escapeClassnames(classes.foreground.fill)} { fill: ${colorToRgbString(foregroundColor)} }`}

      {/* Accent */}
      {`.${escapeClassnames(classes.accent.backgroundColor)} { background-color: ${colorToString(accentColor)} }`}
      {`.${escapeClassnames(classes.accent.color)} { color: ${colorToString(accentColor)} }`}
      {`.${escapeClassnames(classes.accent.fill)} { fill: ${colorToRgbString(accentColor)} }`}

      {/* Background */}
      {`.${escapeClassnames(classes.background.backgroundColor)} { background-color: ${colorToString(backgroundColor)} }`}
      {`.${escapeClassnames(classes.background.color)} { color: ${colorToString(backgroundColor)} }`}
      {`.${escapeClassnames(classes.background.fill)} { fill: ${colorToRgbString(backgroundColor)} }`}

      {/* Highlight */}
      {`.${escapeClassnames(classes.highlight.backgroundColor)} { background-color: ${colorToString(highlightColor)} }`}
      {`.${escapeClassnames(classes.highlight.color)} { color: ${colorToString(highlightColor)} }`}
      {`.${escapeClassnames(classes.highlight.fill)} { fill: ${colorToString(highlightColor)} }`}
      {`.${escapeClassnames(classes.highlight.hoverBackgroundColor)}:hover { background-color: ${colorToString(highlightColor)} }`}
      {`.${escapeClassnames(classes.highlight.borderColor)} { border-color: ${colorToString(highlightColor)} }`}

      {/* Shadow */}
      {`.${escapeClassnames(classes.textShadow)} { text-shadow: 0px 1px 3px ${colorToString(shadowColor)} }`}
      {`.${escapeClassnames(classes.boxShadow)} { box-shadow: 0px 1px 3px ${colorToString(shadowColor)} }`}

      {/* Loader */}
      {`.${escapeClassnames(classes.loader)} { border-top-color: ${colorToRgbString(foregroundColor)} }`}
      {`.${escapeClassnames(classes.loader)}:before { border-top-color: ${colorToRgbString(accentColor)} }`}
      {`.${escapeClassnames(classes.loader)}:after { border-top-color: ${colorToRgbString(backgroundColor)} }`}

      {/* Large Play Button */}
      {`.${escapeClassnames(classes.largePlayButton)} { background-color: ${colorToRgbString(largePlayButtonBackground)}; box-shadow: 0 0 48em ${colorToString(largePlayButtonBackground)}}`}

      {/* ProgressBar */}
      {`.${escapeClassnames(classes.progressBar.borderLeftColor)} { border-left-color: ${colorToString(accentColor)} !important }`}
    </style>
  ), document.head);
}

const mapStateToProps = (state: RootState): DynamicStyleProps => ({
  ...selectCalculatedStyle(state)
});

export default connect(mapStateToProps)(DynamicStyle);
