import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { VideoActions } from '../redux/actions';
import { RootState } from '../redux/models';
import Slider from './Slider';

export interface VolumeSliderProps {
  setVolume: typeof VideoActions.actionCreators.setVolume;
  hitBoxEl?: HTMLElement;
  isMuted: boolean;
  volume: number;
}

class VolumeSlider extends React.Component<VolumeSliderProps> {
  private _volumeFrom: number = 1;
  
  public render = () => {
    const { hitBoxEl, isMuted, volume } = this.props;

    const value = isMuted ? 0 : volume;

    return (
      <Slider
        draggable={true}
        hitBoxEl={hitBoxEl}
        value={value}
        onDragStart={this.handleDragStart}
        onDragEnd={this.handleDragEnd}
        onValueChange={this.handleValueChange}
      />
    );
  }

  private handleDragEnd = () => {
    const { volume } = this.props;

    if(volume === 0) {
      this.props.setVolume({
        isMuted: true,
        volume: Math.min(0.4, this._volumeFrom)
      });
    }
  }

  private handleDragStart = (value: number) => {
    this.props.setVolume({
      isMuted: false,
      volume: value
    });
  }

  private handleValueChange = (value: number) => {
    this.props.setVolume({
      volume: value
    });
  }
}

const mapStateToProps = (state: RootState): Pick<VolumeSliderProps, 'isMuted' | 'volume'> => ({
  isMuted: state.video.isMuted,
  volume: state.video.volume
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<VolumeSliderProps, 'setVolume'> => ({
  setVolume: bindActionCreators(VideoActions.actionCreators.setVolume, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(VolumeSlider);
