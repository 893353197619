import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { analytics, video, userActivity, interactive } from '../middlewares';
import { RootState } from '../models';
import { createRootReducer } from '../reducers';
import { composeWithDevTools } from '@redux-devtools/extension';

export const configureStore = (history: History<any>, initialState?: RootState): Store<RootState> => {
  let middleware = applyMiddleware(thunk, interactive, video, userActivity, analytics, routerMiddleware(history));

  if (process.env.NODE_ENV !== 'production') {
    middleware = composeWithDevTools(middleware);
  }

  const rootReducer = createRootReducer(history);
  
  const store: Store<RootState> = createStore(rootReducer, initialState, middleware);

  return store;
}