import { AsyncWorker } from 'typescript-fsa-redux-thunk';
import { getAuthToken } from '.';

import { ErrorActions } from '..';
import { PlayerError, WithStatus } from '../../../models';
import { PostErrorRequest } from '../../../models/requests';
import { mediaErrorCreator, playerErrorCreator, withStatus } from '../../../utils';
import { RootState } from '../../models';

export const logErrorWorker = <T extends MediaError | PlayerError>(apiRoot: string) : AsyncWorker<T, WithStatus<{}>, RootState> => async (params, dispatch, getState) => {
  
    let errorMessage;
    if (typeof (params) === typeof (MediaError)) {
      
        errorMessage = mediaErrorCreator(params as MediaError);
    } else {
        errorMessage = playerErrorCreator(params as PlayerError)
    }

  // Dispatch action for display
  dispatch(ErrorActions.actionCreators.logError(errorMessage));
  //get authToken
  const token = await getAuthToken("player.write", getState, dispatch);

  const request: PostErrorRequest = {
    message: `${errorMessage.message}\r\n${errorMessage.devMessage}`,
    stackTrace: errorMessage.stackTrace
  };

  const data = getState().data;

  const url = `${apiRoot}/errors/${data.isLoaded ? data.sessionId : ''}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      "Content-Type": 'application/json',
      "Authorization": `${token}`
    },
    body: JSON.stringify(request)
  });
  const result = withStatus({
    ok: response.ok,
    status: response.status
  })({});

  return result;
};