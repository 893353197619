import { evaluateTemplate, Template, VariableDictionary } from '@videosmart/player-template';
import { createSelector } from 'reselect';

import { RootState } from '../models';

export const selectEvaluatedTemplate = createSelector(
  (state: RootState) => (state.data.isLoaded && state.data.variables) || undefined,
  (state: RootState) => (state.data.isLoaded && state.data.template) || undefined,
  (variables: VariableDictionary | undefined, template: Template | undefined) => {
    if(variables && template) {
      return evaluateTemplate(template, variables);
    }
  }
);