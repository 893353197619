import { EvaluatedControlOptions } from '@videosmart/player-template';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../redux/models';
import { selectAreSubtitlesAvailable, selectControlOptions, selectIsControlBarVisible } from '../redux/selectors';
import styles from './ControlBar.module.scss';
import DownloadButton from './DownloadButton';
import { classes } from './DynamicStyle';
import FullscreenToggle from './FullscreenToggle';
import PlayToggle from './PlayToggle';
import ProgressBar from './ProgressBar';
import SubtitleToggle from './SubtitleToggle';
import TimeDisplay from './TimeDisplay';
import VolumeControls from './VolumeControls';


export interface ControlBarProps {
  areSubtitlesAvailable: boolean;
  controlOptions: EvaluatedControlOptions;
  hasError: boolean;
  isControlBarVisible: boolean;
  isMobile: boolean;
}

const ControlBar: React.FC<ControlBarProps> = (props: ControlBarProps) => {
  const { areSubtitlesAvailable, controlOptions: { enableDownload, enableFullscreen, enableSubtitles, enableControls }, isControlBarVisible, hasError, isMobile } = props;

  const className = classNames(styles["root"], classes.background.backgroundColor, {
    [styles["inactive"]]: !isControlBarVisible,
    [styles["hidden"]]: hasError || !enableControls
  });

  return (
    <div className={className}>
      <div className={styles["controls-left"]}>
        <PlayToggle />
        {!isMobile && <VolumeControls />}
        <TimeDisplay />
      </div>
      <div className={styles["controls-right"]}>
        {enableFullscreen && <FullscreenToggle />}
        {enableSubtitles && areSubtitlesAvailable && <SubtitleToggle />}
        {enableDownload && <DownloadButton />}
      </div>
      <ProgressBar />
    </div>
  );
}

const mapStateToProps = (state: RootState): Pick<ControlBarProps, 'areSubtitlesAvailable' | 'controlOptions' | 'hasError' | 'isControlBarVisible' | 'isMobile'> => ({
  areSubtitlesAvailable: selectAreSubtitlesAvailable(state),
  controlOptions: selectControlOptions(state),
  hasError: state.error.hasError,
  isControlBarVisible: selectIsControlBarVisible(state),
  isMobile: state.context.isMobile
});

export default connect(mapStateToProps)(ControlBar);
