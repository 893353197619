import { createSelector } from 'reselect';

import { CachedSubtitleTrack } from '../../models';
import { RootState } from '../models';
import { selectCurrentSubtitleTrack } from './selectCurrentSubtitleTrack';

export const selectAreSubtitlesEnabled = createSelector(
  (state:RootState) => state.context.areSubtitlesEnabled,
  (state:RootState) => selectCurrentSubtitleTrack(state),
  (areSubtitlesEnabled:boolean, currentSubtitleTrack:CachedSubtitleTrack | undefined) => {
    return areSubtitlesEnabled && !!currentSubtitleTrack;
  }
);