import * as React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {fromEvent, Subscription} from 'rxjs';

import {withUtilityService} from '../hoc/withUtilityService';
import {ContextActions} from '../redux/actions';
import {RootState} from '../redux/models';
import {selectIsFullscreen} from '../redux/selectors';
import {UtilityService} from './UtilityServiceProvider';

export const fullscreenServiceProviderRef = React.createRef<FullscreenServiceProvider>();

export interface FullscreenService {
    exitFullscreen: () => void;
    requestFullscreen: () => void;
    toggleFullscreen: (value?: boolean) => void;
}

interface FullscreenApi {
    requestFullscreen: string;
    exitFullscreen: string;
    fullscreenElement: string;
    fullscreenEnabled: string;
    fullscreenChange: string;
    fullscreenError: string;
}

export const FullscreenServiceContext = React.createContext<FullscreenService>({
    exitFullscreen: ()=>{},
    requestFullscreen: ()=>{},
    toggleFullscreen: ()=>{},
});

export interface FullscreenServiceProviderProps {
    children?: React.ReactNode;
    isFullscreen: boolean;
    isUtilityServiceConnected: boolean;
    utilityService: UtilityService;
    nativeFullscreen: typeof ContextActions.actionCreators.nativeFullscreen;
    nativeFullscreenSupported: typeof ContextActions.actionCreators.nativeFullscreenSupported;
}

class FullscreenServiceProvider extends React.Component<FullscreenServiceProviderProps> {
    private _nativeApi?: FullscreenApi;

    private _subscriptions: Subscription[] = [];

    private get isNativeFullscreen() {
        return (this._nativeApi && !!(document as any)[this._nativeApi.fullscreenElement]) || false;
    }

    public componentDidMount = () => {
        this._nativeApi = this.getNativeFullscreenApi();

        if (this._nativeApi) {
            this.props.nativeFullscreenSupported(true);
            this._subscriptions.push(fromEvent(document, this._nativeApi.fullscreenChange).subscribe(this.handleNativeFullscreenChange));
        } else {
            this.props.nativeFullscreenSupported(false);
        }
    }

    public componentWillUnmount = () => {
        this._subscriptions.forEach(x => x.unsubscribe());
        this._subscriptions = [];
    }

    public exitFullscreen = () => {
        if (this.props.isFullscreen) {
            if (this._nativeApi) {
                (document as any)[this._nativeApi.exitFullscreen]();
            } else if (this.props.isUtilityServiceConnected) {
                this.props.utilityService.exitFullscreen();
            }
        }
    }

    public render = () => {
        return (
            <FullscreenServiceContext.Provider value={{
                exitFullscreen: this.exitFullscreen,
                requestFullscreen: this.requestFullscreen,
                toggleFullscreen: this.toggleFullscreen,
            }}>
                {this.props.children}
            </FullscreenServiceContext.Provider>
        )
    }
    
    public requestFullscreen = () => {
        if (!this.props.isFullscreen) {
            if (this._nativeApi) {
                const el = ReactDOM.findDOMNode(this);
                (el as any)[this._nativeApi.requestFullscreen]();
            } else if (this.props.isUtilityServiceConnected) {
                this.props.utilityService.requestFullscreen();
            }
        }
    }

    public toggleFullscreen = (value?: boolean) => {
        if (value == null) {
            if (this.props.isFullscreen) {
                this.exitFullscreen();
            } else {
                this.requestFullscreen();
            }
        } else {
            if (value) {
                this.requestFullscreen();
            } else {
                this.exitFullscreen();
            }
        }
    }

    private getNativeFullscreenApi(): FullscreenApi | undefined {
        // Browser API methods
        const apiMap: FullscreenApi[] = [
            // Standard
            {
                requestFullscreen: 'requestFullscreen',
                exitFullscreen: 'exitFullscreen',
                fullscreenElement: 'fullscreenElement',
                fullscreenEnabled: 'fullscreenEnabled',
                fullscreenChange: 'fullscreenchange',
                fullscreenError: 'fullscreenerror'
            },
            // WebKit
            {
                requestFullscreen: 'webkitRequestFullscreen',
                exitFullscreen: 'webkitExitFullscreen',
                fullscreenElement: 'webkitFullscreenElement',
                fullscreenEnabled: 'webkitFullscreenEnabled',
                fullscreenChange: 'webkitfullscreenchange',
                fullscreenError: 'webkitfullscreenerror'
            },
            // Old WebKit (Safari 5.1)
            {
                requestFullscreen: 'webkitRequestFullScreen',
                exitFullscreen: 'webkitCancelFullScreen',
                fullscreenElement: 'webkitCurrentFullScreenElement',
                fullscreenEnabled: 'webkitCancelFullScreen',
                fullscreenChange: 'webkitfullscreenchange',
                fullscreenError: 'webkitfullscreenerror'
            },
            // Mozilla
            {
                requestFullscreen: 'mozRequestFullScreen',
                exitFullscreen: 'mozCancelFullScreen',
                fullscreenElement: 'mozFullScreenElement',
                fullscreenEnabled: 'mozFullScreenEnabled',
                fullscreenChange: 'mozfullscreenchange',
                fullscreenError: 'mozfullscreenerror'
            },
            // Microsoft
            {
                requestFullscreen: 'msRequestFullscreen',
                exitFullscreen: 'msExitFullscreen',
                fullscreenElement: 'msFullscreenElement',
                fullscreenEnabled: 'msFullscreenEnabled',
                fullscreenChange: 'MSFullscreenChange',
                fullscreenError: 'MSFullscreenError'
            }
        ];

        // Determine the supported set of functions
        for (let i = 0; i < apiMap.length; i++) {
            // Check for exitFullscreen function
            if (apiMap[i].exitFullscreen in document) {
                return apiMap[i];
            }
        }

        return undefined;
    }

    private handleNativeFullscreenChange = () => {
        this.props.nativeFullscreen(this.isNativeFullscreen);
    }
}

const mapStateToProps = (state: RootState): Pick<FullscreenServiceProviderProps, 'isFullscreen' | 'isUtilityServiceConnected'> => ({
    isFullscreen: selectIsFullscreen(state),
    isUtilityServiceConnected: state.context.isUtilityServiceConnected
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<FullscreenServiceProviderProps, 'nativeFullscreen' | 'nativeFullscreenSupported'> => ({
    nativeFullscreen: bindActionCreators(ContextActions.actionCreators.nativeFullscreen, dispatch),
    nativeFullscreenSupported: bindActionCreators(ContextActions.actionCreators.nativeFullscreenSupported, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, {forwardRef: true})(withUtilityService(FullscreenServiceProvider));
