import {
  EvaluatedAction,
  EvaluatedFunction,
  EvaluatedPoint,
  EvaluatedSize,
  EvaluatedStyleEvent,
  EvaluatedToggleFunction,
  EvaluatedTracker,
  ToggleFunctionType,
  TrackerType,
} from '@videosmart/player-template';
import classNames from 'classnames';
import * as React from 'react';
import { fromEvent, Subscription } from 'rxjs';
import { Matrix, inverse } from 'ml-matrix';
import { withFrameUpdates } from '../hoc';
import { InteractiveActions } from '../redux/actions';
import { functionLibrary } from '../utils';
import styles from './Overlay.module.scss';

export interface OverlayProps {
  invokeInteractiveAction: typeof InteractiveActions.actionCreators.invoke;
  actions: EvaluatedAction[];
  baseSize: EvaluatedSize;
  containerHeight: number;
  containerWidth: number;
  content: string;
  currentFrame: number;
  currentTime: number;
  end?: EvaluatedToggleFunction;
  onMount?: EvaluatedFunction;
  onUnmount?: EvaluatedFunction;
  start?: EvaluatedToggleFunction;
  styleEvents: EvaluatedStyleEvent[];
  tracker?: EvaluatedTracker;
  videoIsPlaying: boolean;
}

interface OverlayState {
  className: string;
  containerHeight: number;
  containerWidth: number;
  height?: string;
  isEnabled: boolean;
  trackerFrameId: number;
  transform: string;
  width?: string;
}

class Overlay extends React.Component<OverlayProps, OverlayState> {
  private _elRef: React.RefObject<HTMLDivElement>;

  private _subscriptions: Subscription[];

  constructor(props: OverlayProps) {
    super(props);

    this._elRef = React.createRef();
    this._subscriptions = [];

    this.state = {
      className: '',
      containerHeight: -1,
      containerWidth: -1,
      isEnabled: false,
      trackerFrameId: -1,
      transform: ''
    };
  }

  public static getDerivedStateFromProps = (props: OverlayProps, prevState: OverlayState): OverlayState | null => {
    const { currentTime, end, start, videoIsPlaying } = props;

    const isEnabled = Overlay.evaluateToggleFunctions(currentTime, videoIsPlaying, start, end);

    let state = {
      ...prevState,
      isEnabled
    }

    // Only update the overlay if it's enabled
    if (isEnabled) {
      const {
        currentFrame,
        containerHeight,
        containerWidth,
        styleEvents,
        tracker
      } = props;
      const {
        containerHeight: prevContainerHeight,
        containerWidth: prevContainerWidth,
        trackerFrameId: prevTrackerFrameId,
      } = prevState;

      // Calculate style events
      state.className = styleEvents
        .filter(x => Overlay.evaluateToggleFunctions(currentTime, videoIsPlaying, x.start, x.end))
        .map(x => x.className)
        .join(' ');

      if (tracker) {
        // The index of the track data frame to use
        const trackerFrameId = Math.max(0, Math.min(tracker.trackData.frames.length - 1, currentFrame + tracker.trackData.offsetFrames));

        // If the tracker frame changed
        if (trackerFrameId !== prevTrackerFrameId || containerWidth !== prevContainerWidth || containerHeight !== prevContainerHeight) {
          const { points } = tracker.trackData.frames[trackerFrameId];

          const { height, transform, width } = Overlay.getTransform(tracker.type, points, props);

          state = {
            ...state,
            containerHeight,
            containerWidth,
            height,
            trackerFrameId,
            transform,
            width
          };
        }
      }
      //no tracker is defined
      else{
        //add class to remove pointer and remove height from overlay to prevent overlay cover the video screen
        state = {
          ...state,
          transform: ''
        };
        state.className += " " + styles["noTracker"];
      }
    }

    return state;
  }

  public componentDidMount = () => {
    const el = this._elRef.current;
    if (el) {
      const { actions } = this.props;

      // Create action handlers
      for (let action of actions) {
        const elements: Element[] = action.selector ? Array.from(el.querySelectorAll(action.selector)) : [el];

        for (let element of elements) {
          this._subscriptions.push(fromEvent<MouseEvent>(element, 'click').subscribe(() => this.handleInteractive(action)));
        }
      }
    }

    this.callFunction(this.props.onMount);
  }
  public componentDidUpdate = (prevProps:any) => {
    if (prevProps.actions !== this.props.actions) {
        const el = this._elRef.current;
        if (el) {
            //duplicated subscriptions remain for cached overlays and those do not run will unmount. so reset subscriptions
            this.resetSubscriptions();
            const { actions } = this.props;

            // Create action handlers
            for (let action of actions) {
                const elements: Element[] = action.selector ? Array.from(el.querySelectorAll(action.selector)) : [el];

                for (let element of elements) {
                    
                    this._subscriptions.push(fromEvent<MouseEvent>(element, 'click').subscribe(() => this.handleInteractive(action)));
                }
            }
        }
        this.setState(() => {
            return {
                ...this.state,
                trackerFrameId: -1
            }
        });
        this.callFunction(this.props.onMount);
    }
  }

  public componentWillUnmount = () => {
    this.callFunction(this.props.onUnmount);

      this.resetSubscriptions();
  }
   

  public render = () => {
    const { content } = this.props;
    const { height, isEnabled, transform, width } = this.state;

    const className = classNames(styles["root"], this.state.className, {'show': isEnabled});

    return (
      <div
        ref={this._elRef}
        className={className}
        dangerouslySetInnerHTML={{ __html: content }}
        style={{
          display: isEnabled ? 'block' : 'none',
          cursor: isEnabled ? 'pointer' :'none',
          height,
          opacity: isEnabled ? 1 : 0.2,
          transform,
          WebkitTransform: transform,
          msTransform: transform,
          OTransform: transform,
          width
        }}
        onClick={this.handleClick}
      />
    );
  }
  private handleClick = (event: React.MouseEvent) => {
    //avoid PlayVideoArea from resuming or pausing video. if overlay needs to pause it needs to be added as an action
    event.stopPropagation();
  };

  private static evaluateToggleFunction = (currentTime: number, videoIsPlaying: boolean, toggleFunction: EvaluatedToggleFunction): boolean => {
    switch (toggleFunction.type) {
      case ToggleFunctionType.TimeUpdate: {
        return toggleFunction.time <= currentTime;
      }
      case ToggleFunctionType.OnPause: {
        return !videoIsPlaying;
      }
      case ToggleFunctionType.OnPlay: {
        return videoIsPlaying;
      }
    }
  }

  private static evaluateToggleFunctions = (currentTime: number, videoIsPlaying: boolean, start?: EvaluatedToggleFunction, end?: EvaluatedToggleFunction): boolean => {
    const isStarted = start ? Overlay.evaluateToggleFunction(currentTime, videoIsPlaying, start) : true;
    const isEnded = end ? Overlay.evaluateToggleFunction(currentTime, videoIsPlaying, end) : false;

    return isStarted && !isEnded;
  }

  private static getTransform = (type: TrackerType, points: EvaluatedPoint[], props: OverlayProps): Pick<OverlayState, 'height' | 'transform' | 'width'> => {
    const { height: overlayHeight, width: overlayWidth } = props.baseSize;
    const { containerHeight, containerWidth } = props;

    const height = overlayHeight !== 1 ? `${(overlayHeight * 100)}%` : undefined;
    const width = overlayWidth !== 1 ? `${(overlayWidth * 100)}%` : undefined;
    let transform = '';

    switch (type) {
      case TrackerType.Perspective: {
        // Source Points
        const srcPoints = [
          [0, 0],
          [containerWidth * overlayWidth, 0],
          [containerWidth * overlayWidth, containerHeight * overlayHeight],
          [0, containerHeight * overlayHeight]
        ];

        // Destination Points
        const destPoints = [
          [points[0].x * containerWidth, points[0].y * containerHeight],
          [points[1].x * containerWidth, points[1].y * containerHeight],
          [points[2].x * containerWidth, points[2].y * containerHeight],
          [points[3].x * containerWidth, points[3].y * containerHeight]
        ];


        // Transformation Matrix, inverse it
        const left = inverse(new Matrix([
          [srcPoints[0][0], srcPoints[0][1], 1, 0, 0, 0, -1 * destPoints[0][0] * srcPoints[0][0], -1 * destPoints[0][0] * srcPoints[0][1]],
          [0, 0, 0, srcPoints[0][0], srcPoints[0][1], 1, -1 * destPoints[0][1] * srcPoints[0][0], -1 * destPoints[0][1] * srcPoints[0][1]],
          [srcPoints[1][0], srcPoints[1][1], 1, 0, 0, 0, -1 * destPoints[1][0] * srcPoints[1][0], -1 * destPoints[1][0] * srcPoints[1][1]],
          [0, 0, 0, srcPoints[1][0], srcPoints[1][1], 1, -1 * destPoints[1][1] * srcPoints[1][0], -1 * destPoints[1][1] * srcPoints[1][1]],
          [srcPoints[2][0], srcPoints[2][1], 1, 0, 0, 0, -1 * destPoints[2][0] * srcPoints[2][0], -1 * destPoints[2][0] * srcPoints[2][1]],
          [0, 0, 0, srcPoints[2][0], srcPoints[2][1], 1, -1 * destPoints[2][1] * srcPoints[2][0], -1 * destPoints[2][1] * srcPoints[2][1]],
          [srcPoints[3][0], srcPoints[3][1], 1, 0, 0, 0, -1 * destPoints[3][0] * srcPoints[3][0], -1 * destPoints[3][0] * srcPoints[3][1]],
          [0, 0, 0, srcPoints[3][0], srcPoints[3][1], 1, -1 * destPoints[3][1] * srcPoints[3][0], -1 * destPoints[3][1] * srcPoints[3][1]]
        ]));
        
        // Init Destination Vector
        const right = Matrix.columnVector([
          destPoints[0][0],
          destPoints[0][1],
          destPoints[1][0],
          destPoints[1][1],
          destPoints[2][0],
          destPoints[2][1],
          destPoints[3][0],
          destPoints[3][1]
        ]);

        // Calculate Matrix / Vector Product
        const t = (left.mmul(right) as Matrix).to1DArray() as number[];

        // Create CSS Style
        transform = `matrix3d(${t[0]},${t[3]},0,${t[6]},${t[1]},${t[4]},0,${t[7]},0,0,1,0,${t[2]},${t[5]},0,1)`;

        break;
      }
      case TrackerType.ScaleAndTranslate: {
        // Calculate Variables
        const translateX = points[0].x * containerWidth;
        const translateY = points[0].y * containerHeight;
        const horizontalScale = (points[2].x - points[0].x) / overlayWidth;
        const verticalScale = (points[2].y - points[0].y) / overlayHeight;

        // Create CSS Style
        transform = `matrix(${horizontalScale},0,0,${verticalScale},${translateX},${translateY})`;

        break;
      }
    }

    // Save new transform
    return {
      height,
      transform,
      width
    };
    }
    private resetSubscriptions = () => {
        for (let subscription of this._subscriptions) {
            subscription.unsubscribe();
        }
        this._subscriptions = [];
    }

  private callFunction = (evaluatedFunction: EvaluatedFunction | undefined) => {
    if (evaluatedFunction) {
      const fn = functionLibrary.find(evaluatedFunction.name);

      const rootEl = this._elRef.current;

      if (fn) {
        fn.apply(rootEl, evaluatedFunction.args);
      } else {
        console.error(`Function doesn't exist!`, fn);
      }
    }
  }

  private handleInteractive = (action: EvaluatedAction) => {
    const { invokeInteractiveAction } = this.props;
    const { isEnabled } = this.state;

    if (isEnabled) {
      invokeInteractiveAction(action, {
        rootEl: this._elRef.current
      });
    }
  }
}

export default withFrameUpdates(Overlay);
