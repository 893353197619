import { MenuItem, Menu } from "@mui/material";
import { Subtitles } from "@mui/icons-material";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ContextActions } from "../redux/actions";
import { RootState } from "../redux/models";
import CheckBoxBase from "./CheckBoxBase";
import { ISubtitleLanguage, selectAvailableSubtitleLanguages } from "../redux/selectors/selectAvailableSubtitleLanguages";
import { selectSubtitleOptions } from "../redux/selectors/selectSubtitleOptions";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { EvaluatedSubtitleOptions } from "@videosmart/player-template/lib/models/evaluated/EvaluatedSubtitleOptions";
import styles from "./ControlBar.module.scss";

const theme = createTheme({
	components: {
		MuiMenu: {
			styleOverrides: {
				paper: {
					right: "10px !important",
					bottom: "50px !important",
					transformOrigin: "bottom right",
					top: "auto !important",
					left: "auto !important",
					width: "180px",
					backgroundColor: "rgba(28,28,28,0.9)",
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					display: "none",
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					"&.Mui-selected": {
						backgroundColor: "#2B3341",
					},
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					"&:hover": {
						backgroundColor: "#2B3341",
					},
					padding: "10px 16px",
					fontSize: "1.15rem",
					color: "white",
				},
			},
		},
	},
});

export interface SubtitleToggleProps {
	isChecked: boolean;
	toggleSubtitles: typeof ContextActions.actionCreators.toggleSubtitles;
	selectedSubtitleLanguage: typeof ContextActions.actionCreators.selectedSubtitleLanguage;
	availableSubtitleLanguages: ISubtitleLanguage[];
	subtitleOptions: EvaluatedSubtitleOptions;
}
interface SubtitleToggleState {
	showSelectLanguage: boolean;
	selectedSubtitleLanguageCode: string;
}
class SubtitleToggle extends React.Component<SubtitleToggleProps, SubtitleToggleState> {
	subtitlesOff: string = "Off";
	constructor(props: SubtitleToggleProps) {
		super(props);

		this.state = {
			showSelectLanguage: false,
			selectedSubtitleLanguageCode: "",
		};
	}

	public componentDidMount = () => {
		const { subtitleOptions, toggleSubtitles } = this.props;

		if (subtitleOptions.defaultLanguage) {
			this.setState({
				selectedSubtitleLanguageCode: subtitleOptions.defaultLanguage,
			});

			toggleSubtitles(true);
		}
	};

	public render = () => {
		const { isChecked, availableSubtitleLanguages, subtitleOptions } = this.props;
		const { showSelectLanguage, selectedSubtitleLanguageCode } = this.state;

		return (
			<>
				<CheckBoxBase checked={isChecked} onChange={this.handleChange} className={showSelectLanguage ? styles["selected"] : ""}>
					<Subtitles />
					{showSelectLanguage && (availableSubtitleLanguages.length > 1  &&
						<ThemeProvider theme={theme}>
							<Menu
								container={() => document.getElementById("player")}
								open={showSelectLanguage}
								defaultValue={subtitleOptions.defaultLanguage}
								disableScrollLock={true} //fix bar shift when popup open https://github.com/mui/material-ui/issues/17353
							>
								{availableSubtitleLanguages.map((availableSubtitle) => {
									const selected = selectedSubtitleLanguageCode === availableSubtitle.languageCode;
									return (
										<MenuItem
											key={availableSubtitle.languageCode}
											selected={selected}
											onClick={(e) => this.handleChangeOfLanguage(e, availableSubtitle.languageCode)}
											value={availableSubtitle.languageCode}
										>
											{availableSubtitle.language}
										</MenuItem>
									);
								})}
							</Menu>
						</ThemeProvider>
					)}
				</CheckBoxBase>
			</>
		);
	};

	private handleChange = (value: boolean) => {
		if (this.props.availableSubtitleLanguages.length === 1) {
			this.props.selectedSubtitleLanguage(this.props.availableSubtitleLanguages[0].languageCode);
			this.props.toggleSubtitles(value);
		}
		this.setState((prevState) => ({
			showSelectLanguage: !prevState.showSelectLanguage,
		}));
	};

	private handleChangeOfLanguage = (e: any, availableSubtitle: string) => {
		e.stopPropagation();
		this.setState({
			showSelectLanguage: false,
			selectedSubtitleLanguageCode: availableSubtitle,
		});
		this.props.selectedSubtitleLanguage(availableSubtitle);
		if (availableSubtitle.toUpperCase() === this.subtitlesOff.toUpperCase()) {
			this.props.toggleSubtitles(false);
		} else {
			this.props.toggleSubtitles(true);
		}
	};
}

const mapStateToProps = (state: RootState): Pick<SubtitleToggleProps, "isChecked" | "availableSubtitleLanguages" | "subtitleOptions"> => ({
	isChecked: state.context.areSubtitlesEnabled,
	availableSubtitleLanguages: selectAvailableSubtitleLanguages(state),
	subtitleOptions: selectSubtitleOptions(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<SubtitleToggleProps, "toggleSubtitles" | "selectedSubtitleLanguage"> => ({
	toggleSubtitles: bindActionCreators(ContextActions.actionCreators.toggleSubtitles, dispatch),
	selectedSubtitleLanguage: bindActionCreators(ContextActions.actionCreators.selectedSubtitleLanguage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubtitleToggle);
