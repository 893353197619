import { Color } from '@videosmart/player-template';

import { colorToRgbaString } from './colorToRgbaString';
import { colorToRgbString } from './colorToRgbString';

export const colorToString = (color: Color): string => {
  if (color.alpha === 1) {
    return colorToRgbString(color);
  } else {
    return colorToRgbaString(color);
  }
}