import * as React from 'react';
import { BehaviorSubject, Subscription } from 'rxjs';

import { FrameUpdateEvent } from '../models';

export const frameUpdateSubject = new BehaviorSubject<FrameUpdateEvent>({ currentFrame: 0, currentTime: 0 });

export const FrameUpdateContext = React.createContext(frameUpdateSubject.value);

export interface FrameUpdateProviderProps {
  children?: React.ReactNode;
}

interface FrameUpdateProviderState extends FrameUpdateEvent {
}

export default class FrameUpdateProvider extends React.Component<FrameUpdateProviderProps, FrameUpdateProviderState> {
  private _subscription?: Subscription;

  constructor(props: FrameUpdateProviderProps) {
    super(props);

    this.state = {
      currentFrame: 0,
      currentTime: 0
    }
  }

  public componentDidMount = () => {
    this._subscription = frameUpdateSubject.subscribe(this.handleFrameUpdate);
  }

  public componentWillUnmount = () => {
    if(this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  private handleFrameUpdate = (e: FrameUpdateEvent) => {
    this.setState(() => e);
  }

  public render = () => {
    const { children } = this.props;

    return (
      <FrameUpdateContext.Provider value={this.state}>
        { children }
      </FrameUpdateContext.Provider>
    );
  }
}