export enum EventType {
  // Player Events                  (0 - 15)
  PlayerDataRequest               = 0,
  PlayerDataLoaded                = 1,
  PlayerError                     = 2,
  PlayerLeave                     = 3,
  PlayerEnterFullscreen           = 4,
  PlayerExitFullscreen            = 5,

  // Scene Events                   (16 - 31)
  SceneLoaded                     = 16,
  SceneSubtitleChange             = 17,
  SceneTag                        = 18,

  // Video Events                   (32 - 47)
  VideoLoaded                     = 32,
  VideoError                      = 33,
  VideoPlay                       = 34,
  VideoPause                      = 35,
  VideoSeeked                     = 36,
  VideoEnded                      = 37,
  VideoVolumeChange               = 38,
  VideoUnloaded                   = 39,
  VideoDownload                   = 40,
  VideoPartialEnd                 = 41,

  // Rating Events                  (48 - 63)
  Rating                          = 48,
  ThumbsUp                        = 49,
  ThumbsDown                      = 50,
}

export const eventTypeName: any = {
  0: 'PlayerDataRequest',
  1: 'PlayerDataLoaded',
  3: 'PlayerLeave',
  4: 'PlayerEnterFullscreen',
  5: 'PlayerExitFullscreen',
  16: 'SceneLoaded',
  17: 'SceneSubtitleChange',
  18: 'SceneTag',
  32: 'VideoLoaded',
  34: 'VideoPlay',
  35: 'VideoPause',
  36: 'VideoSeeked',
  37: 'VideoEnded',
  38: 'VideoVolumeChange',
  39: 'VideoUnloaded',
  40: 'VideoDownload',
  41: 'VideoPartialEnd',
  48: 'Rating',
  49: 'ThumbsUp',
  50: 'ThumbsDown'
}

export const eventDOMTypeName: any = {
  2: 'error', //PlayerError
  4: 'fullscreenchange', //PlayerEnterFullscreen
  5: 'fullscreenchange', //PlayerExitFullscreen
  18: 'ctaclick', //SceneTag
  32: 'loaded', //VideoLoaded
  33: 'error', //VideoError
  34: 'play', //VideoPlay
  35: 'pause', //VideoPause
  36: 'seeking', //VideoSeeked
  37: 'ended', //VideoEnded
  38: 'volumechange', //VideoVolumeChange
  40: 'download', //VideoDownload
  41: 'partialend', //VideoPartialEnd
  48: 'rating', //Rating
  49: 'thumbsup', //ThumbsUp
  50: 'thumbsdown', //ThumbsDown
}