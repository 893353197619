import { actionCreatorFactory } from 'typescript-fsa';
import { VideoLoadedEvent, SetVolumeEvent } from '../../models';

enum Type {
  LogDownload = 'LOG_DOWNLOAD',
  LogIsEnded = 'LOG_IS_ENDED',
  LogIsLoaded = 'LOG_IS_LOADED',
  LogProgress = 'LOG_PROGRESS',
  LogIsPlaying = 'LOG_IS_PLAYING',
  LogTimeUpdate = 'LOG_TIME_UPDATE',
  LogWaiting = 'LOG_WAITING',
  LogIsPartialEnd = 'LOG_IS_PARTIAL_END',
  Pause = 'PAUSE',
  Play = 'PLAY',
  Seek = 'SEEK',
  SetVolume = 'SET_VOLUME'
}

const create = actionCreatorFactory('VIDEO');

// Async Action Creators
export const asyncActionCreators = {
};

// Action Creators
export const actionCreators = {
  logDownload: create(Type.LogDownload),
  logIsEnded: create<boolean>(Type.LogIsEnded),
  logIsLoaded: create<VideoLoadedEvent>(Type.LogIsLoaded),
  logIsPlaying: create<boolean>(Type.LogIsPlaying),
  logProgress: create<[number, number][]>(Type.LogProgress),
  logTimeUpdate: create<number>(Type.LogTimeUpdate),
  logWaiting: create(Type.LogWaiting),
  logIsPartialEnd: create<boolean>(Type.LogIsPartialEnd),
  pause: create(Type.Pause),
  play: create(Type.Play),
  seek: create<number>(Type.Seek),
  setVolume: create<SetVolumeEvent>(Type.SetVolume),
};