import { ConstantSubtitleTrack } from '@videosmart/player-template/lib/models/template/SubtitleTrack';

import { RootState } from '../models';
import { selectCurrentScene } from './selectCurrentScene';

export interface ISubtitleLanguage {
  languageCode: string;
  language: string;
}

export const selectAvailableSubtitleLanguages = (state: RootState) => {
  const currentEvaluatedScene = selectCurrentScene(state);
  if(!currentEvaluatedScene){
    return [];
  }
  const currentSceneId = currentEvaluatedScene.id;
  const currentScene = state.data.isLoaded ? state.data.template.scenes.find(x=> x.id === currentSceneId) : undefined;
  if(!currentScene){
    return [];
  }
  
  const subtitleTrackList = currentEvaluatedScene.subtitles;
  if(!subtitleTrackList)
  {
    return [];
  }

  const availableLanguages = subtitleTrackList.map(x=> ({
    language: (x as ConstantSubtitleTrack).label,
    languageCode: (x as ConstantSubtitleTrack).language,
  } as ISubtitleLanguage));

  // Including Off  when more than one subtitle language is available
  if(availableLanguages.length > 1){
    availableLanguages.unshift({
      language: "Subtitles off",
      languageCode: "off"
    });
  }
  return availableLanguages;
};