import { RootState } from '../models';

export const selectBufferedTime = (state: RootState): number => {
  const { buffered, currentTime, duration } = state.video;
  
  for(let [start, end] of buffered) {
    if(start <= currentTime && currentTime <= end) {
      return end / duration;
    }
  }
  
  return 0;
};