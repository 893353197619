import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Observable } from 'rxjs';

import { VideoActions } from '../redux/actions';
import { RootState } from '../redux/models';
import { selectPosterSrc } from '../redux/selectors';
import styles from './Poster.module.scss';

export interface PosterProps {
  play: typeof VideoActions.actionCreators.play;
  hasStarted: boolean;
  posterSrc?: string;
}

class Poster extends Component<PosterProps> {
  public render = () => {
    const { hasStarted, posterSrc } = this.props;

    const className = classNames(styles["root"], {
      [styles["hidden"]]: hasStarted
    });

    return (posterSrc ? 
      <div
        className={className}
        onClick={this.handleClick}
        style={{
          backgroundImage: `url(${posterSrc})`
        }}
      /> : <div
        className={className}
        onClick={this.handleClick}
      />
    );
  }


  private handleClick = () => {
    this.props.play();
  }

  private loadImage = (src: string): Observable<Event> => {
    return new Observable(observer => {
      const img = new Image();
      img.onload = (e) => { observer.next(e); observer.complete(); };
      img.onerror = (e) => { observer.error(e); observer.complete(); };
      img.src = src;
    });
  }
}

const mapStateToProps = (state: RootState): Pick<PosterProps, 'hasStarted' | 'posterSrc'> => ({
  hasStarted: state.context.hasStarted,
  posterSrc: selectPosterSrc(state)
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<PosterProps, 'play'> => ({
  play: bindActionCreators(VideoActions.actionCreators.play, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Poster);