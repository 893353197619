import { functionCallServiceProviderRef } from '../services/FunctionCallServiceProvider';
import { fullscreenServiceProviderRef } from '../services';
import { LoadSceneEventArgs } from '../models';
import { ActionType, EvaluatedTagAction } from '@videosmart/player-template';

type AnyFunction = (...args: any[]) => any;

class FunctionLibrary {
	private readonly _functions: { [name: string]: AnyFunction };

	private _listenEventsTriggered: string[];

	constructor() {
		this._functions = {};
		this._listenEventsTriggered = [];
	}

	public find = (functionName: string): AnyFunction | undefined => {
		return this._functions[functionName];
	};

	public register = (functionName: string, fn: AnyFunction) => {
		this._functions[functionName] = fn;
	};

	public unregister = (functionName: string) => {
		delete this._functions[functionName];
	};

	public callParentFunction = (functionName: string, args?: any[]) => {
		if (functionCallServiceProviderRef.current) {
			functionCallServiceProviderRef.current.callParentFunction(functionName, args);
		}
		return;
	};
	public playerId = () => {
		if (functionCallServiceProviderRef.current) {
			return functionCallServiceProviderRef.current.playerId;
		}
		return;
	};
	public collectAnalytics = () => {
		if (functionCallServiceProviderRef.current) {
			return functionCallServiceProviderRef.current.collectAnalytics ? 1 : 0;
		}
		return;
	};
	public isEnded = () => {
		if (functionCallServiceProviderRef.current) {
			return functionCallServiceProviderRef.current.isEnded;
		}
		return;
	};

	public createEvent = (eventName: string) => {
		if (document.createEvent) {
			var event = document.createEvent('HTMLEvents');
			event.initEvent(eventName, true, true);
			const element = document.getElementById('player');
			if (element) {
				element.dispatchEvent(event);
			}
		}
	};
	public loadSceneSimplified = (sceneId: string, autoPlay?:boolean) => {
		const loadScene: LoadSceneEventArgs = { sceneId: sceneId, autoPlay: (autoPlay ? autoPlay:true) };
		this.loadScene(loadScene);
		return;
	};

	public eventTriggeredOnce = (eventName: string, functionVariable: () => any): void => {
		var playerDom = document.getElementById('player');
		var eventsTriggered = this._listenEventsTriggered;
		if (playerDom) {
			playerDom.addEventListener(eventName, function () {
				if (
					eventsTriggered.filter(function (item: string) {
						return item === eventName;
					}).length === 0
				) {
					eventsTriggered.push(eventName); //add event to global list to mark it as handled
					try {
						if (functionVariable) functionVariable();
					} catch (err) {
						console.log(err);
					}
				}
			});
		}
	};
	public interactiveHandler = (data: any) => {
		var functionCall = this;
		var name = 'CTA';

		// If data is (parsed?) object
		if (typeof data === 'object') {
			if (data.hasOwnProperty('name')) {
				name = data.name;
			}
			if (data.hasOwnProperty('events')) {
				// Create tag event
				this.invokeTagAction({ tag: name, type: ActionType.Tag });

				data.events.forEach(function (event: any) {
					switch (event.type) {
						case 'play': {
							functionCall.play();
							break;
						}
						case 'pause': {
							functionCall.pause();
							break;
						}
						case 'jump': {
							// Jump to time offset in video
							if (event.options.hasOwnProperty('time')) {
								if (!isNaN(Number(event.options.time))) {
									functionCall.seek(event.options.time);
								} else {
									functionCall.seek(parseInt(event.options.time));
								}
							}
							break;
						}
						case 'playlist-link': {
							functionCall.loadSceneSimplified(event.options.scene, true);
							break;
						}
						case 'external-url': {
							var src = event.options.src;
							var target = event.options.target || '_blank';
							var pauseonclick = event.options.pauseonclick || !event.options.hasOwnProperty('pauseonclick');

							// Pause video if requested
							if (pauseonclick) {
								functionCall.pause();
							}

							// Open window
							window.open(src, target);
							break;
						}
						case 'requestFullscreen': {
							functionCall.requestFullscreen();
							break;
						}
						case 'exitFullscreen': {
							functionCall.exitFullscreen();
							break;
						}
						case 'function-call': {
							// Call function specified in options
							if (typeof event.options.function === 'function') {
								try {
									event.options.function();
								} catch (err) {}
							}
							break;
						}
						case 'parent-function-call': {
							functionCall.callParentFunction(event.options.name, event.options.args);
							break;
						}
						default: {
							console.log('Unknown Event Type! (' + event.type + ')');
							break;
						}
					}
				});
			}
		}
	};
	public contentVariables = () => {
		if (functionCallServiceProviderRef.current) {
			return functionCallServiceProviderRef.current.contentVariables;
		}
		return;
	};
	public pause = () => {
		if (functionCallServiceProviderRef.current) {
			functionCallServiceProviderRef.current.pause();
		}
		return;
	};
	public play = () => {
		if (functionCallServiceProviderRef.current) {
			functionCallServiceProviderRef.current.play();
		}
		return;
	};
	public currentScene = () => {
		if (functionCallServiceProviderRef.current) {
			return functionCallServiceProviderRef.current.currentScene;
		}
		return;
	};
	public currentTime = () => {
		if (functionCallServiceProviderRef.current) {
			return functionCallServiceProviderRef.current.currentTime;
		}
		return;
	};
	public seek = (time: number) => {
		if (functionCallServiceProviderRef.current) {
			functionCallServiceProviderRef.current.seek(time);
		}
		return;
	};
	public currentDuration = () => {
		if (functionCallServiceProviderRef.current) {
			return functionCallServiceProviderRef.current.currentDuration;
		}
		return;
	};

	private requestFullscreen = () => {
		if (fullscreenServiceProviderRef.current) {
			fullscreenServiceProviderRef.current.requestFullscreen();
		}
		return;
	};
	private exitFullscreen = () => {
		if (fullscreenServiceProviderRef.current) {
			fullscreenServiceProviderRef.current.exitFullscreen();
		}
		return;
	};
	private loadScene = (params: LoadSceneEventArgs) => {
		if (functionCallServiceProviderRef.current) {
			functionCallServiceProviderRef.current.loadScene(params);
		}
		return;
	};
	
	private invokeTagAction = (action: EvaluatedTagAction) => {
		if (functionCallServiceProviderRef.current) {
			functionCallServiceProviderRef.current.invokeInteractiveAction(action);
		}
		return;
	};
	
}

export const functionLibrary = new FunctionLibrary();

(window as any).player = {
	registerFunction: functionLibrary.register,
	callParentFunction: functionLibrary.callParentFunction,
	playerId: functionLibrary.playerId,
	collectAnalytics: functionLibrary.collectAnalytics,
	createEvent: functionLibrary.createEvent,
	one: functionLibrary.eventTriggeredOnce,
	interactiveHandler: functionLibrary.interactiveHandler,
	loadScene: functionLibrary.loadSceneSimplified,
	isEnded: functionLibrary.isEnded,
	find: functionLibrary.find,
	contentVariables: functionLibrary.contentVariables, //get content variables from player variables. use ContentVariables
	play: functionLibrary.play,
	pause: functionLibrary.pause,
	currentScene: functionLibrary.currentScene,
	currentTime: functionLibrary.currentTime,
	seek: functionLibrary.seek,
	currentDuration: functionLibrary.currentDuration
};
