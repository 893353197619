import { VariableDictionary, VariableValueType } from '@videosmart/player-template';
import { createSelector } from 'reselect';

import { ContentVariableDictionary } from '../../models/ContentVariableDictionary';
import { RootState } from '../models';

export const selectContentVariableDictionary = createSelector(
  (state:RootState) => (state.data.isLoaded && state.data.variables) || {},
  (variables:VariableDictionary) => {
    const contentVariables: ContentVariableDictionary = {};

    for (let key in variables) {
      const variable = variables[key];

      switch (variable.type) {
        case VariableValueType.String:
        case VariableValueType.Number: {
          contentVariables[key] = variable.value;
          break;
        }
      }
    }

    return contentVariables;
  }
);