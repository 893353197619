import { ErrorType } from '../enums/ErrorTypes';
import { ErrorMessage, PlayerError } from '../models';

const unknownErrorMessage: ErrorMessage = {
  message: "An error occurred. Please try again later.",
  allowReload: true
};

const playerErrorMessages: { [id: number]: Partial<ErrorMessage> } = {
  [ErrorType.PlayerIdMissing]: {
    devMessage: "Player identifier is missing.",
    allowReload: false
    },
    [ErrorType.PlayerNotFound]: {
        message: "Player not found.",
        allowReload: false
    }
};

export const playerErrorCreator = (error: PlayerError): ErrorMessage => {
  const { type, ...rest } = error;
  
  const originalError = playerErrorMessages[error.type];

  const result: ErrorMessage = {
    ...unknownErrorMessage,
    ...originalError,
    ...rest
  };

  return result;
}