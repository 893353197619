export const raf: (callback: FrameRequestCallback) => number = (    
  window.requestAnimationFrame ||
(window as any).webkitRequestAnimationFrame || 
  function(callback: FrameRequestCallback) {
    return window.setTimeout(callback, 1000 / 60);
  }).bind(window);

export const caf: (handle: number) => void = (   
  window.cancelAnimationFrame ||
  (window as any).webkitCancelAnimationFrame ||
  function(handle: number) {
    window.clearInterval(handle);
  }).bind(window);
          