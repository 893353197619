import React from "react";
import { Component } from "react";
import styles from './ContextMenu.module.scss';

interface ContextMenuProps {
    top: number;
    left: number;
};

export class ContextMenu extends Component<ContextMenuProps>{

	public render = () => {
        return (
            <div className={styles['root']} style={{ top: this.props.top, left: this.props.left }} >
                <ul>
                    <li><a href="mailto:tech@videosmart.com"><span>Report an anomaly</span></a></li>
                    <li><a href="https://www.videosmart.com" target="_blank" rel="noreferrer"><span>Powered by VideoSmart</span></a></li>
                </ul>
            </div>
		);
	};
}