import { EvaluatedSubtitleTrack } from '@videosmart/player-template';
import { createSelector } from 'reselect';

import { RootState } from '../models';
import { selectCurrentScene } from './selectCurrentScene';

export const selectAreSubtitlesAvailable = createSelector(
  (state: RootState) =>  {
    const currentScene = selectCurrentScene(state);
    if(currentScene && currentScene.subtitles) {
      return currentScene.subtitles;
    } else {
      return [];
    }
  },
  (subtitleTracks:EvaluatedSubtitleTrack[]) => subtitleTracks.length > 0
);