import { hasValue } from './hasValue';

export const createEventThrottler = <TEvent>(
  update: (existing: TEvent, event: TEvent) => TEvent,
  callback: (event: TEvent) => void,
  timeout: number = 5000
) => {
  interface State {
    event?: TEvent;
    timeout?: number;
  }

  const state: State = {};

  const fire = () => {
    if(state.event) {
      callback(state.event);
      state.event = undefined;
      state.timeout = undefined;
    }
  }

  const log = (event: TEvent) => {
    // Create event or merge with existing
    state.event = hasValue(state.event) ? update(state.event, event) : event;

    // Clear previous timeout (if exists)
    window.clearTimeout(state.timeout);

    // Register next timeout
    state.timeout = window.setTimeout(fire, timeout);
  };

  return { fire, log };
}