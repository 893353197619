import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";

import { RootState } from "../redux/models";
import styles from "./Loader.module.scss";
import { selectIsPlayerLoading } from "../redux/selectors";

export interface LoaderProps {
  isLoading: boolean;
  hasError: boolean;
}

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const { hasError, isLoading } = props;

  const className = classNames(styles["root"], {
    [styles["hidden"]]: !isLoading,
    [styles["disabled"]]: hasError,
  });

  return (
      <div id="playArrow_Loader" className={className}>
      <svg
        width="95"
        height="111"
        viewBox="0 0 95 111"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.882 11.618L0.401001 0.949951V67.5099L18.882 78.18V11.618Z"
          fill="url(#paint0_linear_103_2070)"
        />
        <path
          d="M0.431946 110.402L58.05 77.0749L58.034 55.7339L0.412964 89.0619L0.431946 110.402Z"
          fill="url(#paint1_linear_103_2070)"
        />
        <path
          d="M37.299 22.3099L18.816 32.9739L76.516 66.3109L95 55.6449L37.299 22.3099Z"
          fill="url(#paint2_linear_103_2070)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_103_2070"
            x1="3.72758"
            y1="8.98187"
            x2="31.6202"
            y2="18.5987"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_103_2070"
            x1="16.4937"
            y1="96.6802"
            x2="52.8133"
            y2="60.8138"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_103_2070"
            x1="90.7337"
            y1="57.2907"
            x2="53.0326"
            y2="12.328"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

const mapStateToProps = (
  state: RootState
): Pick<LoaderProps, "isLoading" | "hasError"> => ({
  isLoading: selectIsPlayerLoading(state),
  hasError: state.error.hasError,
});

export default connect(mapStateToProps)(Loader);
