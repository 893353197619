import * as React from 'react';

import { FrameUpdateContext } from '../services';

interface WithFrameUpdatesProps {
  currentFrame: number;
  currentTime: number;
}

export const withFrameUpdates = <ComposedComponentProps extends WithFrameUpdatesProps>(ComposedComponent: React.ComponentClass<ComposedComponentProps>) => {
  type ComposedComponentInstance = InstanceType<typeof ComposedComponent>;

  type WrapperComponentProps = Omit<ComposedComponentProps, 'currentFrame' | 'currentTime'>;
  type WrapperComponentPropsWithForwardedRef = WrapperComponentProps & {
    forwardedRef: React.Ref<ComposedComponentInstance>;
  };

  const WrapperComponent = ({ forwardedRef, ...rest }: WrapperComponentPropsWithForwardedRef) => {
    const context = React.useContext(FrameUpdateContext);

    return (
      <ComposedComponent
        ref={forwardedRef}
        // We need a cast because:
        // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32355
        // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
        {...rest as unknown as ComposedComponentProps}
        currentFrame={context.currentFrame}
        currentTime={context.currentTime}
      />
    );
  };

  return React.forwardRef<ComposedComponentInstance, WrapperComponentProps>((props, ref) => <WrapperComponent forwardedRef={ref} {...props} />);
}