import { EvaluatedOverlay } from "@videosmart/player-template";
import Mustache from "mustache";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ErrorType } from "../enums";
import { withResponsiveSize } from "../hoc";
import { CachedOverlay, ContentVariableDictionary } from "../models";
import { EndpointActions, InteractiveActions, VideoActions } from "../redux/actions";
import { RootState } from "../redux/models";
import { selectContentVariableDictionary, selectCurrentOverlays } from "../redux/selectors";
import { removeFromParent } from "../utils";
import Overlay from "./Overlay";
import styles from "./OverlayDisplay.module.scss";

export interface OverlayDisplayProps {
	invokeInteractiveAction: typeof InteractiveActions.actionCreators.invoke;
	content: string;
	height: number;
	overlays: EvaluatedOverlay[];
	variables: ContentVariableDictionary;
	videoIsPlaying: boolean;
	width: number;
	playerError: typeof EndpointActions.actionCreators.logPlayerError;
	pause: typeof VideoActions.actionCreators.pause;
}

interface OverlayDisplayState {
	cachedOverlays: CachedOverlay[];
	overlayContainer: DocumentFragment;
	prevProps?: OverlayDisplayProps;
	scripts: HTMLScriptElement[];
	styles: HTMLStyleElement[];
}

class OverlayDisplay extends React.Component<OverlayDisplayProps, OverlayDisplayState> {
	constructor(props: OverlayDisplayProps) {
		super(props);

		this.state = {
			cachedOverlays: [],
			overlayContainer: document.createDocumentFragment(),
			prevProps: undefined,
			scripts: [],
			styles: [],
		};
	}

	public static getDerivedStateFromProps = (props: OverlayDisplayProps, prevState: OverlayDisplayState): OverlayDisplayState => {
		const { content, overlays, variables } = props;
		const { prevProps } = prevState;

		const state: OverlayDisplayState = {
			...prevState,
			prevProps: props,
		};

		if (!prevProps || content !== prevProps.content || variables !== prevProps.variables) {
			const { overlayContainer, scripts, styles } = prevState;

			// Clear previous data
			while (overlayContainer.firstChild) {
				overlayContainer.removeChild(overlayContainer.firstChild);
			}
			scripts.forEach((el) => removeFromParent(el));
			styles.forEach((el) => removeFromParent(el));

			if (content && variables) {
				// Load template to in-memory div
				const contentTemplate = document.createElement("div");
				contentTemplate.innerHTML = Mustache.render(content, variables);

				// Load style tags from content template
				Array.from(contentTemplate.getElementsByTagName("style")).forEach((style) => {
					removeFromParent(style);

					document.head.appendChild(style);
					styles.push(style);
				});

				// Load script tags from content template
				Array.from(contentTemplate.getElementsByTagName("script")).forEach((originalScript) => {
					removeFromParent(originalScript);

					const script = document.createElement("script");

					if (originalScript.src) {
						script.src = originalScript.src;
					} else {
						const scriptContent = document.createTextNode(originalScript.innerText);
						script.appendChild(scriptContent);
					}

					if (originalScript.async) {
						script.async = originalScript.async;
					}
					if (originalScript.type) {
						script.type = originalScript.type;
					}

					document.head.appendChild(script);
					scripts.push(script);
				});

				overlayContainer.appendChild(contentTemplate);
			}
		}

		if (!prevProps || overlays !== prevProps.overlays) {
			state.cachedOverlays = overlays.map(
				(overlay, index): CachedOverlay => ({
					...overlay,
					htmlContent: overlay.content ? OverlayDisplay.getOverlayContentById(props, state.overlayContainer, overlay.content.embeddedId) : undefined,
					key: index,
				})
			);
		}

		return state;
	};

	public render = () => {
		const { height, invokeInteractiveAction, videoIsPlaying, width } = this.props;
		const { cachedOverlays } = this.state;

		return (
			<div className={styles["root"]}>
				{cachedOverlays.map(({ content, htmlContent, ...rest }) => (
					<Overlay containerHeight={height} containerWidth={width} content={htmlContent || ""} invokeInteractiveAction={invokeInteractiveAction} videoIsPlaying={videoIsPlaying} {...rest} />
				))}
			</div>
		);
	};

	private static getOverlayContentById = (props: OverlayDisplayProps, overlayContainer: DocumentFragment, id: string) => {
		try {
			const overlay = overlayContainer.getElementById ? overlayContainer.getElementById(id) : overlayContainer.querySelector(`#${id}`);

			if (overlay) {
				return overlay.innerHTML;
			}
		} catch (e) {
			let message = "Unknown error.";
			if (e instanceof Error) {
				message = e.message;
			}
			props.pause();
			props.playerError({
				type: ErrorType.Unknown,
				message: `Error (${message as string})`,
				devMessage: "Error getting Id from overlay container",
				allowReload: false,
			});
		}
	};
}

const mapStateToProps = (state: RootState): Pick<OverlayDisplayProps, "content" | "overlays" | "variables" | "videoIsPlaying"> => ({
	content: (state.data.isLoaded && state.data.template.content) || "",
	overlays: selectCurrentOverlays(state),
	variables: selectContentVariableDictionary(state),
	videoIsPlaying: state.video.isPlaying,
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<OverlayDisplayProps, "invokeInteractiveAction" | "playerError" | "pause"> => ({
	invokeInteractiveAction: bindActionCreators(InteractiveActions.actionCreators.invoke, dispatch),
	playerError: bindActionCreators(EndpointActions.actionCreators.logPlayerError, dispatch),
	pause: bindActionCreators(VideoActions.actionCreators.pause, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, { forwardRef: true })(withResponsiveSize(OverlayDisplay));
