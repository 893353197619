import { EvaluatedVideoSource, VideoSourceType } from '@videosmart/player-template';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { first } from 'rxjs/operators';

import { EndpointActions, VideoActions } from '../redux/actions';
import { RootState } from '../redux/models';
import { selectCurrentVideoSrc } from '../redux/selectors';
import { frameUpdateSubject } from '../services';
import { getAmazonS3Url, raf, hasValue } from '../utils';
import styles from './Video.module.scss';
import { fromEvent } from 'rxjs';

export const videoRef = React.createRef<Video>();

export interface VideoProps {
	logIsEnded: typeof VideoActions.actionCreators.logIsEnded;
	logIsLoaded: typeof VideoActions.actionCreators.logIsLoaded;
	logIsPlaying: typeof VideoActions.actionCreators.logIsPlaying;
	logMediaError: typeof EndpointActions.actionCreators.logMediaError;
	logProgress: typeof VideoActions.actionCreators.logProgress;
	logTimeUpdate: typeof VideoActions.actionCreators.logTimeUpdate;
	logWaiting: typeof VideoActions.actionCreators.logWaiting;
	pause: typeof VideoActions.actionCreators.pause;
	play: typeof VideoActions.actionCreators.play;
	signAsset: typeof EndpointActions.actionCreators.signAsset;
	logIsPartialEnd: typeof VideoActions.actionCreators.logIsPartialEnd;
	isLooped: boolean;
	isMuted: boolean;
	isPlaying: boolean;
	videoSource?: EvaluatedVideoSource;
	volume: number;
	autoPlay: boolean;
}

interface VideoState {
	error?: MediaError;
	errorTime: number;
	frameRate: number;
	hasError: boolean;
	source?: string;
	hasPartialEnd: boolean;
}

class Video extends Component<VideoProps, VideoState> {
	private _currentFrame: number;

	private _rafToken: number;

	private _videoRef: React.RefObject<HTMLVideoElement>;

	constructor(props: VideoProps) {
		super(props);

		this.state = {
			error: undefined,
			errorTime: 0,
			frameRate: 25,
			hasError: false,
			source: undefined,
			hasPartialEnd: false
		};

		this._currentFrame = 0;
		this._rafToken = 0;
		this._videoRef = React.createRef();
	}

	public get isPlaying(): boolean {
		if (this._videoRef.current) {
			return !this._videoRef.current.paused;
		} else {
			return false;
		}
	}

	public get currentTime(): number {
		if (this._videoRef.current) {
			return this._videoRef.current.currentTime;
		} else {
			return 0;
		}
	}

	public set currentTime(value: number) {
		if (this._videoRef.current) {
			this._videoRef.current.currentTime = value;
		}
	}

	public componentDidUpdate = (prevProps: VideoProps) => {
		if (this._videoRef.current) {
			const { isLooped, isMuted, videoSource, volume, autoPlay } = this.props;
			const video = this._videoRef.current;

			if (isLooped !== prevProps.isLooped) {
				video.loop = this.props.isLooped;
			}

			if (isMuted !== prevProps.isMuted) {
				video.muted = this.props.isMuted;
			}

			if (volume !== prevProps.volume) {
				video.volume = this.props.volume;
			}

			if (videoSource !== prevProps.videoSource) {
				this.setVideoSource(videoSource);
			}

			if (autoPlay !== prevProps.autoPlay) {
				video.autoplay = this.props.autoPlay;
			}
		}
	};

	public load = () => {
		if (this._videoRef.current) {
			this._videoRef.current.load();
		}
	};

	public pause = () => {
		if (this._videoRef.current) {
			this._videoRef.current.pause();
		}
	};

	public play = () => {
		if (this._videoRef.current) {
			const playPromise = this._videoRef.current.play();
			// Play is not a promise on older browsers
			if (hasValue(playPromise) && typeof playPromise.then === 'function') {
				playPromise.then(undefined, (e: DOMException) => {
					// https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
					if (this._videoRef.current && (e.name === 'AbortError' || (e.ABORT_ERR && e.code === e.ABORT_ERR))) {
						const subscription = fromEvent(this._videoRef.current, 'loadstart')
							.pipe(first())
							.subscribe(() => {
								this.play();
								subscription.unsubscribe();
							});
					}
				});
			}
		}
	};

	public render = () => {
		return (
			<video
				disablePictureInPicture
				ref={this._videoRef}
				className={styles['root']}
				controls={false}
				controlsList={'nodownload nofullscreen'}
				onDurationChange={this.handleDurationChange}
				onEnded={this.handleEnded}
				onError={this.handleError}
				onLoadedData={this.handleLoadedData}
				onPause={this.handlePause}
				onPlay={this.handlePlay}
				onProgress={this.handleProgress}
				onSeeked={this.handleSeeked}
				onTimeUpdate={this.handleTimeUpdate}
				onWaiting={this.handleWaiting}
				playsInline={true}
				preload={'auto'}
				src={this.state.source}
			/>
		);
	};

	public seekTo = (time: number) => {
		this.currentTime = time;
		this.frameUpdateCheck();
	};

	private frameUpdateCheck = () => {
		const { frameRate } = this.state;

		const time = this.currentTime;
		const frame = Math.floor(time * frameRate);
		if (this._currentFrame !== frame) {
			this._currentFrame = frame;
			frameUpdateSubject.next({ currentFrame: frame, currentTime: time });
		}

		// Schedule next frame check if still playing
		this._rafToken = 0;
		if (this.props.isPlaying) {
			this.requestFrameUpdateCheck();
		}
	};

	private handleDurationChange = () => {
		this.handleProgress();
	};

	private handleEnded = () => {
		this.props.logIsEnded(true);
	};

	private handleError = () => {
		const { hasError } = this.state;

		if (!hasError) {
			console.warn('Trying to resolve video...');

			this.setState(() => ({
				error: (this._videoRef.current && this._videoRef.current.error) || undefined,
				errorTime: (this._videoRef.current && this._videoRef.current.currentTime) || 0,
				hasError: true,
			}));

			this.setVideoSource(this.props.videoSource);
		} else {
			this.props.logMediaError(this.state.error);
		}
	};

	private handleLoadedData = () => {
		if (this._videoRef.current) {
			const video = this._videoRef.current;

			if (this.state.hasError) {
				video.currentTime = this.state.errorTime;
				video.play();

				this.setState(() => ({
					error: undefined,
					errorTime: 0,
					hasError: false,
				}));
			}

			const height = video.videoHeight;
			const width = video.videoWidth;

			if (height === 0 && width === 0) {
				window.setTimeout(() => this.handleLoadedData(), 100);
			} else {
				this.props.logIsLoaded({
					aspectRatio: width / height,
					duration: video.duration,
				});
			}

			this.handleProgress();
		}
	};

	private handlePause = () => {
		this.props.logIsPlaying(false);

		this.frameUpdateCheck();
	};

	private handlePlay = () => {
		this.props.logIsPlaying(true);

		this.frameUpdateCheck();
	};

	private handleProgress = () => {
		if (this._videoRef.current) {
			const { buffered } = this._videoRef.current;

			const bufferedTimes: [number, number][] = [];

			for (let i = 0; i < buffered.length; i++) {
				bufferedTimes.push([buffered.start(i), buffered.end(i)]);
			}

			this.props.logProgress(bufferedTimes);

			this.handlePartialEnd();
		}
	};

	private handleSeeked = () => {
		this.frameUpdateCheck();
	};

	private handleTimeUpdate = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
		const currentTime = e.currentTarget.currentTime;
		this.props.logTimeUpdate(currentTime);

		this.frameUpdateCheck();
	};

	private handleWaiting = () => {
		this.props.logWaiting();
	};

	private requestFrameUpdateCheck = () => {
		// Prevent concurrent requests
		if (this._rafToken === 0) {
			this._rafToken = raf(this.frameUpdateCheck);
		}
	};

	private setVideoSource = async (videoSource?: EvaluatedVideoSource) => {
		if (videoSource) {
			switch (videoSource.type) {
				case VideoSourceType.S3: {
					if (videoSource.isPrivate) {
						const { url } = await this.props.signAsset({
							s3Key: videoSource.s3Key,
							s3Bucket: videoSource.s3Bucket,
							s3Region: videoSource.s3Region,
						});
						this.setState(() => ({
							frameRate: videoSource.frameRate,
							source: url,
						}));
					} else {
						this.setState(() => ({
							frameRate: videoSource.frameRate,
							source: getAmazonS3Url(videoSource.s3Bucket, videoSource.s3Key),
						}));
					}
					break;
				}
				case VideoSourceType.Url: {
					this.setState(() => ({
						frameRate: videoSource.frameRate,
						source: videoSource.source,
					}));
					break;
				}
			}
		} else {
			this.setState(() => ({
				source: undefined,
			}));
		}
	};
  private handlePartialEnd = () => {
		if (this._videoRef.current && !this.state.hasPartialEnd) {
			if (this.currentTime >= this._videoRef.current.duration * 0.8) {
				this.props.logIsPartialEnd(true);
				this.setState(() => ({
					hasPartialEnd: true,
				}));
			}
		}
	};
}

const mapStateToProps = (state: RootState): Pick<VideoProps, 'isLooped' | 'isMuted' | 'isPlaying' | 'videoSource' | 'volume' | 'autoPlay'> => ({
	isLooped: state.video.isLooped,
	isMuted: state.video.isMuted,
	isPlaying: state.video.isPlaying,
	videoSource: selectCurrentVideoSrc(state),
	volume: state.video.volume,
	autoPlay: state.video.autoPlay,
});

const mapDispatchToProps = (
	dispatch: Dispatch
): Pick<VideoProps, 'logIsEnded' | 'logIsLoaded' | 'logIsPlaying' | 'logMediaError' | 'logProgress' | 'logTimeUpdate' | 'logWaiting' | 'pause' | 'play' | 'signAsset' | 'logIsPartialEnd'> => ({
	logIsEnded: bindActionCreators(VideoActions.actionCreators.logIsEnded, dispatch),
	logIsLoaded: bindActionCreators(VideoActions.actionCreators.logIsLoaded, dispatch),
	logIsPlaying: bindActionCreators(VideoActions.actionCreators.logIsPlaying, dispatch),
	logMediaError: bindActionCreators(EndpointActions.actionCreators.logMediaError, dispatch),
	logProgress: bindActionCreators(VideoActions.actionCreators.logProgress, dispatch),
	logTimeUpdate: bindActionCreators(VideoActions.actionCreators.logTimeUpdate, dispatch),
	logWaiting: bindActionCreators(VideoActions.actionCreators.logWaiting, dispatch),
	pause: bindActionCreators(VideoActions.actionCreators.pause, dispatch),
	play: bindActionCreators(VideoActions.actionCreators.play, dispatch),
	signAsset: bindActionCreators(EndpointActions.actionCreators.signAsset, dispatch),
	logIsPartialEnd: bindActionCreators(VideoActions.actionCreators.logIsPartialEnd, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, { forwardRef: true })(Video);
