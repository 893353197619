import { EvaluatedAction } from '@videosmart/player-template';
import { actionCreatorFactory } from 'typescript-fsa';

enum Type {
  Invoke = 'INVOKE'
}

const create = actionCreatorFactory('INTERACTIVE');

// Async Action Creators
export const asyncActionCreators = {
};

// Action Creators
export const actionCreators = {
  invoke: create<EvaluatedAction>(Type.Invoke)
};