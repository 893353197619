import * as React from 'react';

import { ResponsiveSizeContext } from '../utils';

interface WithResponsiveSize {
  height: number;
  width: number;
}

export const withResponsiveSize = <ComposedComponentProps extends WithResponsiveSize>(ComposedComponent: React.ComponentClass<ComposedComponentProps>) => {
  type ComposedComponentInstance = InstanceType<typeof ComposedComponent>;

  type WrapperComponentProps = Omit<ComposedComponentProps, 'height' | 'width'>;
  type WrapperComponentPropsWithForwardedRef = WrapperComponentProps & {
    forwardedRef: React.Ref<ComposedComponentInstance>;
  };

  const WrapperComponent = ({ forwardedRef, ...rest }: WrapperComponentPropsWithForwardedRef) => {
    const context = React.useContext(ResponsiveSizeContext);

    return (
      <ComposedComponent
        ref={forwardedRef}
        // We need a cast because:
        // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32355
        // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
        {...rest as unknown as ComposedComponentProps}
        height={context.height}
        width={context.width}
      />
    );
  };

  return React.forwardRef<ComposedComponentInstance, WrapperComponentProps>((props, ref) => <WrapperComponent forwardedRef={ref} {...props} />);
}