import classNames from "classnames";
import * as React from "react";

import styles from "./ButtonBase.module.scss";
import { bindActionCreators, Dispatch } from "redux";
import { ContextActions } from "../redux/actions";
import { connect } from "react-redux";

export interface ButtonBaseProps {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  reportUserActivity?: boolean;
  userActive: typeof ContextActions.actionCreators.userActive;
}

const ButtonBase: React.FC<ButtonBaseProps> = (props: ButtonBaseProps) => {
  const { children, disabled, href, onClick, userActive } = props;

  const className = classNames(styles["root"], props.className, {
    [styles["disabled"]]: disabled || false,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.reportUserActivity !== false) {
      userActive({});
    }
    if (!disabled && onClick) {
      onClick(event);
    }
  };

  return !!href ? (
    <a className={className} href={href} onClick={handleClick}>
      {children}
    </a>
  ) : (
    <button className={className} onClick={handleClick}>
      {children}
    </button>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch
): Pick<ButtonBaseProps, "userActive"> => ({
  userActive: bindActionCreators(
    ContextActions.actionCreators.userActive,
    dispatch
  ),
});

export default connect(undefined, mapDispatchToProps)(ButtonBase);
