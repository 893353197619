import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { ErrorActions } from '../actions';
import { ErrorState } from '../models';

const initialState: ErrorState = {
  hasError: false,
  message: '',
  allowReload: true,
  devMessage: undefined
};

export const errorReducer = reducerWithInitialState(initialState)
  // Error
  .case(ErrorActions.actionCreators.logError, (state, { message, devMessage, allowReload }) => ({
    ...state,
    hasError: true,
    message,
    devMessage,
    allowReload
  }))