import * as React from "react";
import { notInitialized } from "../utils";
import { UtilityService } from "./UtilityServiceProvider";
import { withUtilityService } from "../hoc";
import { connect } from "react-redux";
import { RootState } from "../redux/models";
import { selectCollectAnalytics, selectContentVariableDictionary, selectIsEnded, selectPlayerId, selectCurrentScene, selectCurrentTime } from "../redux/selectors";
import { ContextActions, InteractiveActions, VideoActions } from "../redux/actions";
import { ActionCreator, bindActionCreators, Dispatch } from "redux";
import { ThunkFn } from "typescript-fsa-redux-thunk";
import { ContentVariableDictionary, SceneLoadedEvent } from "../models";
import { EvaluatedScene } from "@videosmart/player-template";
import { selectDuration } from "../redux/selectors/selectDuration";

export const functionCallServiceProviderRef = React.createRef<FunctionCallServiceProvider>();

export interface FunctionCallService {
	callParentFunction: (functionName: string, args?: any[]) => void;
	playerId?: string;
	collectAnalytics: boolean;
	pause: ActionCreator<void>;
	play: ActionCreator<void>;
	loadScene: ThunkFn<unknown, SceneLoadedEvent>;
	seek: ActionCreator<void>;
	invokeInteractiveAction: ActionCreator<void>;
	isEnded: boolean;
	contentVariables: string | number;
	currentTime: number;
	currentScene: string | undefined;
	currentDuration: number;
}

export const FunctionCallServiceContext = React.createContext<FunctionCallService>({
	callParentFunction: notInitialized("PlayerService"),
	playerId: undefined,
	collectAnalytics: false,
	pause: notInitialized("PlayerService"),
	play: notInitialized("PlayerService"),
	loadScene: notInitialized("PlayerService"),
	seek: notInitialized("PlayerService"),
	invokeInteractiveAction: notInitialized("PlayerService"),
	isEnded: false,
	contentVariables: "",
	currentTime: 0,
	currentScene: undefined,
	currentDuration: 0
});

export interface FunctionCallServiceProviderProps {
	utilityService: UtilityService;
	playerId?: string;
	collectAnalytics: boolean;
	pause: typeof VideoActions.actionCreators.pause;
	play: typeof VideoActions.actionCreators.play;
	loadScene: typeof ContextActions.actionCreators.loadScene;
	seek: typeof VideoActions.actionCreators.seek;
	invokeInteractiveAction: typeof InteractiveActions.actionCreators.invoke;
	isEnded: boolean;
	contentVariables: ContentVariableDictionary;
	currentTime: number;
	currentScene: EvaluatedScene | undefined;
	currentDuration: number;
}

class FunctionCallServiceProvider extends React.Component<FunctionCallServiceProviderProps> {
	public callParentFunction = (functionName: string, args?: any[]) => {
		this.props.utilityService.callParentFunction(functionName, args);
	};
	private getContentVariables = (contentVariables: ContentVariableDictionary) => {
		return contentVariables?.["ContentVariables"] ? JSON.parse(contentVariables["ContentVariables"] as string) : undefined;
	};
	public playerId = this.props.playerId;
	public collectAnalytics = this.props.collectAnalytics;
	public isEnded = this.props.isEnded;

	public pause = this.props.pause;
	public play = this.props.play;
	public loadScene = this.props.loadScene;
	public seek = this.props.seek;
	public invokeInteractiveAction = this.props.invokeInteractiveAction;
	public contentVariables = this.getContentVariables(this.props.contentVariables);
	public currentTime = this.props.currentTime;
	public currentScene = this.props.currentScene ? this.props.currentScene.id : undefined;
	public currentDuration = this.props.currentDuration;

	public render = () => {
		return (
			<FunctionCallServiceContext.Provider
				value={{
					callParentFunction: this.callParentFunction,
					playerId: this.playerId,
					collectAnalytics: this.collectAnalytics,
					pause: this.pause,
					play: this.play,
					loadScene: this.loadScene,
					seek: this.seek,
					invokeInteractiveAction: this.invokeInteractiveAction,
					isEnded: this.isEnded,
					contentVariables: this.contentVariables,
					currentTime: this.currentTime,
					currentScene: this.currentScene,
					currentDuration: this.currentDuration
				}}
			>
				{this.props.children}
			</FunctionCallServiceContext.Provider>
		);
	};
	public componentDidUpdate = (prevProps: FunctionCallServiceProviderProps) => {
		if (prevProps.playerId !== this.props.playerId) {
			this.playerId = this.props.playerId;
		}
		if (prevProps.collectAnalytics !== this.props.collectAnalytics) {
			this.collectAnalytics = this.props.collectAnalytics;
		}
		if (prevProps.isEnded !== this.props.isEnded) {
			this.isEnded = this.props.isEnded;
		}
		if (prevProps.contentVariables !== this.props.contentVariables) {
			this.contentVariables = this.getContentVariables(this.props.contentVariables);
		}
		if (prevProps.currentTime !== this.props.currentTime) {
			this.currentTime = this.props.currentTime;
		}
		if (prevProps.currentScene !== this.props.currentScene) {
			this.currentScene = this.props.currentScene ? this.props.currentScene.id : undefined;
		}
		if(prevProps.currentDuration !== this.props.currentDuration){
			this.currentDuration = this.props.currentDuration;
		}
	};
}
const mapStateToProps = (state: RootState): Pick<FunctionCallServiceProviderProps, "playerId" | "collectAnalytics" | "isEnded" | "contentVariables" | "currentTime" | "currentScene" | "currentDuration"> => ({
	playerId: selectPlayerId(state),
	collectAnalytics: selectCollectAnalytics(state),
	isEnded: selectIsEnded(state),
	contentVariables: selectContentVariableDictionary(state),
	currentTime: selectCurrentTime(state),
	currentScene: selectCurrentScene(state),
	currentDuration: selectDuration(state)
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<FunctionCallServiceProviderProps, "pause" | "play" | "seek" | "loadScene" | "invokeInteractiveAction"> => ({
	pause: bindActionCreators(VideoActions.actionCreators.pause, dispatch),
	play: bindActionCreators(VideoActions.actionCreators.play, dispatch),
	seek: bindActionCreators(VideoActions.actionCreators.seek, dispatch),
	loadScene: bindActionCreators(ContextActions.actionCreators.loadScene, dispatch),
	invokeInteractiveAction: bindActionCreators(InteractiveActions.actionCreators.invoke, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, { forwardRef: true })(withUtilityService(FunctionCallServiceProvider));
