import { actionCreatorFactory } from 'typescript-fsa';
import { ErrorMessage } from '../../models';

enum Type {
  LogError = 'LOG_ERROR'
}

const create = actionCreatorFactory('ERROR');

// Async Action Creators
export const asyncActionCreators = {
};

// Action Creators
export const actionCreators = {
  logError: create<ErrorMessage>(Type.LogError)
};