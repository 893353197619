import React from 'react';
import styles from './PlayerVideoArea.module.scss';
import ResponsiveContainer from './ResponsiveContainer';
import {  VideoActions } from '../redux/actions';

export interface playerVideoAreaProps {
  width: number,
  height: number,
  isPlaying: boolean;
  pause: typeof VideoActions.actionCreators.pause;
  play: typeof VideoActions.actionCreators.play;
}

const PlayerVideoArea = (props: playerVideoAreaProps) => {
  
  const handleClick = (event: React.MouseEvent) => {
    if(props.isPlaying) {
      props.pause();
    }
    else {
      props.play();
    }
  };

  const width = props.width;
  const height = props.height;
  return (
  <div 
    className={styles.PlayerVideoArea}
    style={{width, height}}
    onClick={handleClick}
  >
    <ResponsiveContainer />
  </div>
  );

  
};



export default PlayerVideoArea;
