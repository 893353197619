import classNames from 'classnames';
import * as React from 'react';

import ButtonBase, { ButtonBaseProps } from './ButtonBase';
import styles from './CheckBoxBase.module.scss';
import { classes } from './DynamicStyle';

export interface CheckBoxBaseProps extends Omit<ButtonBaseProps, 'userActive'> {
  checked?: boolean;
  onChange?: (value: boolean) => void;
}

const CheckBoxBase: React.FC<CheckBoxBaseProps> = (props: CheckBoxBaseProps) => {
  const { checked, children, className, onChange, onClick, ...buttonBaseProps } = props;

  const rootClassName = classNames(styles["root"], className, {
    [styles["checked"]]: checked
  });
  const circleClassName = classNames(styles["circle"], classes.accent.backgroundColor, classes.boxShadow);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    
    if(onChange) {
      onChange(!checked);
    }
    if(onClick) {
      onClick(event);
    }
  }

  return (
    <ButtonBase
      {...buttonBaseProps}
      className={rootClassName}
      onClick={handleClick}
      >
      <div className={circleClassName} />
      {children}
    </ButtonBase>
  );
}

export default CheckBoxBase;
