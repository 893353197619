import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {Subscription} from 'rxjs';

import {IAnalytics, UtilityPackMessage, UtilityPackMessageData} from '../models';
import {ContextActions} from '../redux/actions';
import {RootState} from '../redux/models';
import {functionLibrary, onWindowBeforeUnload, onWindowMessage} from '../utils';

export interface UtilityService {
    exitFullscreen: () => void;
    requestFullscreen: () => void;
    callParentFunction: (functionName: string, args?: any[]) => void;
    sendAnalytics: (data: IAnalytics) => void;
}

export const UtilityServiceContext = React.createContext<UtilityService>({
    exitFullscreen: ()=>{},
    requestFullscreen: ()=>{},
    callParentFunction: ()=>{},
    sendAnalytics: ()=>{},
});

export interface UtilityServiceProviderProps {
    children?: React.ReactNode;
    isUtilityPackAvailable: boolean;
    utilityServiceFullscreen: typeof ContextActions.actionCreators.utilityServiceFullscreen;
    utilityServiceConnected: typeof ContextActions.actionCreators.utilityServiceConnected;
}

class UtilityServiceProvider extends React.Component<UtilityServiceProviderProps> {
    private _index: number = -1;

    private _subscriptions: Subscription[] = [];

    public componentDidMount = () => {
        this._subscriptions.push(onWindowMessage.subscribe(this.handleMessageEvent));
        this._subscriptions.push(onWindowBeforeUnload.subscribe(this.releasePlayer));
        window.setTimeout(() => {
            // Deferred player registration if utility pack is loaded after player
            if (!this.props.isUtilityPackAvailable) {
                this.registerPlayer();
            }
        }, 500);
        window.setTimeout(() => {
            // Deferred player registration if utility pack is loaded after player
            if (!this.props.isUtilityPackAvailable) {
                this.registerPlayer();
            }
        }, 5000);
    }

    public componentWillUnmount = () => {
        this._subscriptions.forEach(x => x.unsubscribe());
        this._subscriptions = [];
    }

    public render = () => {
        return (
            <UtilityServiceContext.Provider value={{
                exitFullscreen: this.exitFullscreen,
                requestFullscreen: this.requestFullscreen,
                callParentFunction: this.callParentFunction,
                sendAnalytics: this.sendAnalytics,
            }}>
                {this.props.children}
            </UtilityServiceContext.Provider>
        )
    }

    private createMessage = (data: IAnalytics | UtilityPackMessageData): UtilityPackMessage => {
        const message: UtilityPackMessage = {
            type: "vsp-message",
            origin: window.location.href,
            index: this._index,
            data: data
        };
        return message;
    }

    private callParentFunction = (functionName: string, args?: any[]) => {
        this.sendMessage(this.createMessage({type: "functionCall", functionArgs: args, functionName: functionName}));
    }

    private sendAnalytics = (data: IAnalytics) => {
        this.sendMessage(this.createMessage({type: "analytics", data: data}));
    }

    private exitFullscreen = () => {
        this.sendMessage(this.createMessage({type: "exitFullscreen"}));
        this.props.utilityServiceFullscreen(false);
    }

    private handleMessageEvent = (event: MessageEvent) => {
        try {
            let message;
            try {
                message = event.data;
            } catch (e) {
                return;
            }
            const valid = (
                message.type === "vsp-message" &&
                message.data &&
                message.data.type
            );
            if (valid) {
                switch (message.data.type) {
                    case "index": {
                        if (message.index !== undefined) {
                            this._index = message.index;
                            this.sendMessage(this.createMessage({type: "identify"}));
                            this.props.utilityServiceConnected(true);
                        }
                        break;
                    }
                    case "functionCall": {
                        if (message.data.functionName) {
                            const fn = functionLibrary.find(message.data.functionName);
                            if (fn) {
                                if (message.data.functionArgs) {
                                    fn(...message.data.functionArgs);
                                } else {
                                    fn();
                                }
                            } else {
                                console.error(`Function '${message.data.functionName}' cannot be found!`);
                            }
                        } else {
                            console.error('Function name is not provided!');
                        }
                    }
                }
            }
        } catch (error) {
            console.error(`VideoSmart Utility Pack Service: ${error}`);
        }
    }

    private registerPlayer = () => {
        this.sendMessage(this.createMessage({type: "register"}));
    }

    private releasePlayer = () => {
        this.sendMessage(this.createMessage({type: "release"}));
    }

    private requestFullscreen = () => {
        this.sendMessage(this.createMessage({type: "requestFullscreen"}));
        this.props.utilityServiceFullscreen(true);
    }

    private sendMessage = (message: UtilityPackMessage, targetOrigin: string = "*") => {
        window.parent.postMessage(JSON.stringify(message), targetOrigin);
    }
}

const mapStateToProps = (state: RootState): Pick<UtilityServiceProviderProps, 'isUtilityPackAvailable'> => ({
    isUtilityPackAvailable: state.context.isUtilityServiceConnected,
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<UtilityServiceProviderProps, 'utilityServiceConnected' | 'utilityServiceFullscreen'> => ({
    utilityServiceConnected: bindActionCreators(ContextActions.actionCreators.utilityServiceConnected, dispatch),
    utilityServiceFullscreen: bindActionCreators(ContextActions.actionCreators.utilityServiceFullscreen, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UtilityServiceProvider);
