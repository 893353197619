import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import { RootState } from '../models';
import { contextReducer } from './contextReducer';
import { dataReducer } from './dataReducer';
import { errorReducer } from './errorReducer';
import { videoReducer } from './videoReducer';

export const createRootReducer = (history: History<any>) => combineReducers<RootState>({
  context: contextReducer,
  data: dataReducer,
  error: errorReducer,
  router: connectRouter(history),
  video: videoReducer,
});