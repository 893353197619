import { AsyncWorker } from 'typescript-fsa-redux-thunk';

import { EndpointActions } from '..';
import { ErrorType } from '../../../enums';
import { WithStatus } from '../../../models';
import { SignAssetRequest } from '../../../models/requests';
import { SignAssetResponse } from '../../../models/responses';
import { withStatus } from '../../../utils';
import { RootState } from '../../models';

export const signAssetWorker = (apiRoot: string): AsyncWorker<SignAssetRequest, WithStatus<SignAssetResponse>, RootState> => async (params, dispatch, getState) => {
  const data = getState().data;

  if(!data.isLoaded) {
    // TODO: dispatch error event as well
    throw new Error('Load user data before trying to sign an asset!');
  }

  const url = `${apiRoot}/sign/${data.playerId}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      "Content-Type": 'application/json'
    },
    body: JSON.stringify(params)
  });

  if (!response.ok) {
    switch (response.status) {
      case 404: {
        dispatch(EndpointActions.asyncActionCreators.logPlayerErrorCreator.action({ type: ErrorType.SignRequestFailed }));
        break;
      }
      default: {
        dispatch(EndpointActions.asyncActionCreators.logPlayerErrorCreator.action({ type: ErrorType.Unknown }));
        break;
      }
    }
    throw new Error('Failed to sign asset!');
  }

  const responseText = await response.text();
  const responseBody: SignAssetResponse = JSON.parse(responseText);

  const result = withStatus({
    ok: response.ok,
    status: response.status
  })(responseBody);

  return result;
};