import { middleware } from 'typescript-fsa-redux-middleware';

import { ContextActions } from '../actions';

let userActivityTimeout = 0;

export const userActivity = middleware()
  .case(ContextActions.actionCreators.userActive, (api, next, action) => {
    const timeout = action.payload.timeout || 3500;

    // Clear previous timeout
    window.clearTimeout(userActivityTimeout);
    
    // Cancel user active in 'timeout' ms
    userActivityTimeout = window.setTimeout(() => {
      api.dispatch(ContextActions.actionCreators.userInactive());
    }, timeout);

    next(action);
  });