import * as React from 'react';

import { classes } from './DynamicStyle';
import Slider from './Slider';

export interface HoverProgressProps {
  hitBoxEl?: HTMLElement;
}

interface HoverProgressState {
  value: number;
}

class HoverProgress extends React.Component<HoverProgressProps, HoverProgressState> {
  constructor(props: HoverProgressProps) {
    super(props);

    this.state = {
      value: 0
    };
  }

  public render = () => {
    const { hitBoxEl } = this.props;
    const { value } = this.state;

    return (
      <Slider
        className={classes.highlight.hoverBackgroundColor}
        changeOnHover={true}
        hitBoxEl={hitBoxEl}
        value={value}
        onValueChange={this.handleValueChange}
      />
    );
  }

  private handleValueChange = (value: number) => {
    this.setState(() => ({ value }));
  }
}

export default HoverProgress;
