import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import * as React from 'react';
import { connect } from 'react-redux';

import { withFullscreenService } from '../hoc';
import { RootState } from '../redux/models';
import { selectIsFullscreen, selectIsFullscreenSupported } from '../redux/selectors';
import { FullscreenService } from '../services';
import ButtonBase from './ButtonBase';

export interface FullscreenToggleProps {
  fullscreenService: FullscreenService;
  isFullscreen: boolean;
  isFullscreenSupported: boolean;
}

class FullscreenToggle extends React.Component<FullscreenToggleProps> {
  public render = () => {
    const { isFullscreen, isFullscreenSupported } = this.props;

    return (
      <ButtonBase
        disabled={!isFullscreenSupported} 
        onClick={this.handleClick}
      >
        {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
      </ButtonBase>
    );
  }

  private handleClick = () => {
    const { fullscreenService } = this.props;
    fullscreenService.toggleFullscreen();
  }
}

const mapStateToProps = (state: RootState): Pick<FullscreenToggleProps, 'isFullscreen' | 'isFullscreenSupported'> => ({
  isFullscreen: selectIsFullscreen(state),
  isFullscreenSupported: selectIsFullscreenSupported(state)
});

export default connect(mapStateToProps)(withFullscreenService(FullscreenToggle));
