import { createBrowserHistory } from 'history';
import React from 'react';
import { Provider } from 'react-redux';

import Player from './components/Player';
import { configureStore } from './redux/store';
import EventService, { eventServiceRef } from './services/EventService';
import FrameUpdateProvider from './services/FrameUpdateProvider';
import FullscreenServiceProvider, { fullscreenServiceProviderRef } from './services/FullscreenServiceProvider';
import UtilityServiceProvider from './services/UtilityServiceProvider';
import FunctionCallServiceProvider, {functionCallServiceProviderRef} from "./services/FunctionCallServiceProvider";

const history = createBrowserHistory();
const store = configureStore(history);

class App extends React.Component {
  public render = () => {
    return (
      <Provider store={store}>
        <React.Fragment> 
          <FrameUpdateProvider>
            <UtilityServiceProvider>
              <FunctionCallServiceProvider ref={functionCallServiceProviderRef}/>
              <EventService ref={eventServiceRef} />
              <FullscreenServiceProvider ref={fullscreenServiceProviderRef}>
                <Player />
              </FullscreenServiceProvider>
            </UtilityServiceProvider>
          </FrameUpdateProvider>
        </React.Fragment>
      </Provider>
    );
  }
}

export default App;
