import React, { Component } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/models';
import { onWindowResize, ResponsiveSizeContext } from '../utils';
import OverlayDisplay from './OverlayDisplay';
import styles from './ResponsiveContainer.module.scss';
import Video, { videoRef } from './Video';

export interface ResponsiveContainerProps {
  aspectRatio: number;
}

interface ResponsiveContainerState {
  left: number,
  top: number,
  width: number,
  height: number
}

class ResponsiveContainer extends Component<ResponsiveContainerProps, ResponsiveContainerState> {
  constructor(props: ResponsiveContainerProps) {
    super(props);

    this.state = {
      height: 0,
      left: 0,
      top: 0,
      width: 0
    };
  }


  
  public componentDidMount = () => {
    this.recalculateSize();
    onWindowResize.subscribe(this.handleWindowResize);
  }

  public componentDidUpdate = (prevProps: ResponsiveContainerProps) => {
    if(this.props.aspectRatio !== prevProps.aspectRatio) {
      this.recalculateSize();
    }
  }

  public render = () => {
    const { height, left, top, width } = this.state;
    
    return (
      <div
        className={styles["root"]}
        style={{ height, left, top, width }}
      >
        <ResponsiveSizeContext.Provider value={{ height, width }}>
          <Video ref={videoRef} />
          <OverlayDisplay />
        </ResponsiveSizeContext.Provider>
      </div>
    );
  }


  private handleWindowResize = () => {
    this.recalculateSize();
  }

  private recalculateSize = () => {
    const { aspectRatio } = this.props;
    
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const windowAspectRatio = windowWidth / windowHeight;

    if(aspectRatio >= windowAspectRatio) {
      // Landscape mode
      const width = windowWidth;
      const height = Math.round(width / aspectRatio);
      const top = (windowHeight - height) / 2; 
      const left = 0;

      this.setState(() => ({ height, left, top, width }));
    } else {
      // Portrait mode
      const height = windowHeight;
      const width = Math.round(height * aspectRatio);
      const left = (windowWidth - width) / 2;
      const top = 0;

      this.setState(() => ({ height, left, top, width }));
    }
  }
}

const mapStateToProps = (state: RootState): Pick<ResponsiveContainerProps, 'aspectRatio'> => ({
  aspectRatio: state.video.aspectRatio
});

export default connect(mapStateToProps)(ResponsiveContainer);
