import { EvaluatedOverlay } from '@videosmart/player-template';

import { RootState } from '../models';
import { selectCurrentScene } from './selectCurrentScene';

export const selectCurrentOverlays = (state: RootState): EvaluatedOverlay[] => {
  const currentScene = selectCurrentScene(state);
  if(currentScene) {
    return currentScene.overlays || [];
  } else {
    return [];
  }
};