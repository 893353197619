export const formatTime = (seconds: number, showHours: boolean): string => {
  seconds = (isNaN(seconds) || seconds === Infinity || seconds < 0) ? 0 : seconds;

  const s: number = Math.floor(seconds % 60);
  const m: number = Math.floor(seconds / 60 % 60);

  if(showHours) {
      const h: number = Math.floor(seconds / 3600);
      return `${h.toString()}:${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
  } else {
      return `${m.toString()}:${s.toString().padStart(2, "0")}`;
  }
}