import { middleware } from 'typescript-fsa-redux-middleware';

import { VideoActions, InteractiveActions, ContextActions, EndpointActions } from '../actions';
import { RootState } from '../models';
import { eventServiceRef } from '../../services';
import { Event } from '../../models';

import {
	PlayerDataLoadedEvent,
	PlayerDataRequestEvent,
	PlayerEnterFullscreenEvent,
	PlayerExitFullscreenEvent,
	SceneLoadedEvent,
	SceneSubtitleChangeEvent,
	SceneTagEvent,
	ThumbsDownEvent,
	ThumbsUpEvent,
	VideoDownloadEvent,
	VideoEndedEvent,
	VideoLoadedEvent,
	VideoPartialEndEvent,
	VideoPauseEvent,
	VideoPlayEvent,
	VideoSeekedEvent,
	VideoUnloadedEvent,
	VideoVolumeChangeEvent,
} from '../../models/events';
import { EventType } from '../../enums';
import { getDateTimeOffset, hasValue, createEventThrottler } from '../../utils';
import { selectCurrentSceneId, selectCurrentTime, selectIsFullscreen } from '../selectors';
import { ActionType } from '@videosmart/player-template';

const tryLogEvent = <T extends Event>(event: T) => {
	if (eventServiceRef.current) {
		eventServiceRef.current.logEvent(event);
	}
};

const withEventBase = <T extends EventType>(type: T) => ({
	type,
	eventDateTimeOffset: getDateTimeOffset(),
});

const withSceneEventBase = (state: RootState) => {
	const currentSceneId = selectCurrentSceneId(state);
	return {
		scene: hasValue(currentSceneId) ? currentSceneId : 0,
		sceneTime: selectCurrentTime(state),
	};
};

const videoSeekedEventThrottler = createEventThrottler<VideoSeekedEvent>(
	({ seekedFrom }, event) => ({ ...event, seekedFrom }),
	(event) => event.seekedFrom !== event.seekedTo && tryLogEvent(event)
);

const videoVolumeChangeEventThrottler = createEventThrottler<VideoVolumeChangeEvent>(
	({ volumeFrom }, event) => ({ ...event, volumeFrom }),
	(event) => event.volumeFrom !== event.volumeTo && tryLogEvent(event)
);

export const analytics = middleware<RootState>()
	// Interactive Events (Tag / ThumbsDown / ThumbsUp)
	.case(InteractiveActions.actionCreators.invoke, (api, next, action) => {
		switch (action.payload.type) {
			case ActionType.FunctionCall: {
				const state = api.getState();

				if (!action.payload.function || !action.payload.function.name) break;

				tryLogEvent<SceneTagEvent>({
					...withEventBase(EventType.SceneTag),
					...withSceneEventBase(state),
					tag: action.payload.function.name,
				});

				break;
			}
			case ActionType.Tag: {
				const state = api.getState();

				tryLogEvent<SceneTagEvent>({
					...withEventBase(EventType.SceneTag),
					...withSceneEventBase(state),
					tag: action.payload.tag,
				});

				break;
			}
			case ActionType.ThumbsDown: {
				tryLogEvent<ThumbsDownEvent>({
					...withEventBase(EventType.ThumbsDown),
				});

				break;
			}
			case ActionType.ThumbsUp: {
				tryLogEvent<ThumbsUpEvent>({
					...withEventBase(EventType.ThumbsUp),
				});

				break;
			}
		}

		next(action);
	})

	// Player Data Request
	.case(EndpointActions.asyncActionCreators.getPlayerCreator.async.started, (api, next, action) => {
		tryLogEvent<PlayerDataRequestEvent>({
			...withEventBase(EventType.PlayerDataRequest),
		});

		next(action);
	})

	// Player Data Loaded
	.case(EndpointActions.asyncActionCreators.getPlayerCreator.async.done, (api, next, action) => {
		tryLogEvent<PlayerDataLoadedEvent>({
			...withEventBase(EventType.PlayerDataLoaded),
		});

		next(action);
	})

	// PlayerError
	// TODO

	// Player Enter / Exit Fullscreen
	.cases([ContextActions.actionCreators.nativeFullscreen, ContextActions.actionCreators.utilityServiceFullscreen], (api, next, action) => {
		const state = api.getState();

		const isFullscreen = selectIsFullscreen(state);

		if (isFullscreen) {
			tryLogEvent<PlayerEnterFullscreenEvent>({
				...withEventBase(EventType.PlayerEnterFullscreen),
			});
		} else {
			tryLogEvent<PlayerExitFullscreenEvent>({
				...withEventBase(EventType.PlayerExitFullscreen),
			});
		}

		next(action);
	})

	// Scene Loaded (Branching)
	.case(ContextActions.asyncActionCreators.loadSceneCreator.async.done, (api, next, action) => {
		const state = api.getState();

		tryLogEvent<SceneLoadedEvent>({
			...withEventBase(EventType.SceneLoaded),
			previousScene: state.context.currentSceneIndex,
			scene: action.payload.result.sceneIndex,
			sceneTime: 0,
		});

		next(action);
	})

	// Scene Subtitle Change
	.case(ContextActions.actionCreators.toggleSubtitles, (api, next, action) => {
		const state = api.getState();

		tryLogEvent<SceneSubtitleChangeEvent>({
			...withEventBase(EventType.SceneSubtitleChange),
			...withSceneEventBase(state),
			language: state.context.areSubtitlesEnabled ? state.context.selectedSubtitleLanguage : undefined,
		});

		next(action);
	})

	// Video Loaded
	.case(VideoActions.actionCreators.logIsLoaded, (api, next, action) => {
		const state = api.getState();

		tryLogEvent<VideoLoadedEvent>({
			...withEventBase(EventType.VideoLoaded),
			...withSceneEventBase(state),
			duration: action.payload.duration,
		});

		next(action);
	})

	// Video Error
	// TODO

	// Video Play / Pause
	.case(VideoActions.actionCreators.logIsPlaying, (api, next, action) => {
		const state = api.getState();

		const isPlaying = action.payload;

		if (isPlaying) {
			tryLogEvent<VideoPlayEvent>({
				...withEventBase(EventType.VideoPlay),
				...withSceneEventBase(state),
			});
		} else {
			tryLogEvent<VideoPauseEvent>({
				...withEventBase(EventType.VideoPause),
				...withSceneEventBase(state),
			});
		}

		next(action);
	})

	// Video Seeked (Throttled)
	.case(VideoActions.actionCreators.seek, (api, next, action) => {
		const state = api.getState();

		const seekedFrom = state.video.currentTime;
		const seekedTo = action.payload;

		videoSeekedEventThrottler.log({
			...withEventBase(EventType.VideoSeeked),
			...withSceneEventBase(state),
			seekedFrom,
			seekedTo,
		});

		next(action);
	})

	// Video Ended
	.case(VideoActions.actionCreators.logIsEnded, (api, next, action) => {
		const state = api.getState();

		tryLogEvent<VideoEndedEvent>({
			...withEventBase(EventType.VideoEnded),
			...withSceneEventBase(state),
		});

		next(action);
	})

	// Video Volume Change (Throttled)
	.case(VideoActions.actionCreators.setVolume, (api, next, action) => {
		const state = api.getState();

		const mutedFrom = state.video.isMuted;
		const mutedTo = hasValue(action.payload.isMuted) ? action.payload.isMuted : mutedFrom;

		const volumeFrom = mutedFrom ? 0 : state.video.volume;
		const volumeTo = mutedTo ? 0 : hasValue(action.payload.volume) ? action.payload.volume : volumeFrom;

		videoVolumeChangeEventThrottler.log({
			...withEventBase(EventType.VideoVolumeChange),
			...withSceneEventBase(state),
			volumeFrom,
			volumeTo,
		});

		next(action);
	})

	// Video Unloaded (also fire all pending throttled events)
	.case(ContextActions.actionCreators.unloadScene, (api, next, action) => {
		const state = api.getState();

		// Fire pending events
		// E.g.: When seeking to the end of the scene and branching to another scene,
		// we want to ensure the seeked event is fired with the scene identifier, prior to branching.
		videoSeekedEventThrottler.fire();
		videoVolumeChangeEventThrottler.fire();

		const { duration, maximumTime } = state.video;

		tryLogEvent<VideoUnloadedEvent>({
			...withEventBase(EventType.VideoUnloaded),
			...withSceneEventBase(state),
			duration,
			maximumTime,
		});

		next(action);
	})

	// Video Downloaded
	.case(VideoActions.actionCreators.logDownload, (api, next, action) => {
		const state = api.getState();

		tryLogEvent<VideoDownloadEvent>({
			...withEventBase(EventType.VideoDownload),
			...withSceneEventBase(state),
		});

		next(action);
	})

	// Video Partial Ended
	.case(VideoActions.actionCreators.logIsPartialEnd, (api, next, action) => {
		const state = api.getState();

		tryLogEvent<VideoPartialEndEvent>({
			...withEventBase(EventType.VideoPartialEnd),
			...withSceneEventBase(state),
		});

		next(action);
	});
