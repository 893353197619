import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { interval } from 'rxjs';

import { eventDOMTypeName, EventType, eventTypeName } from '../enums';
import { Event } from '../models';
import { EndpointActions } from '../redux/actions';
import { getDateTimeOffset, onWindowBeforeUnload, hasValue } from '../utils';
import { RootState } from '../redux/models';
import { selectSessionId } from '../redux/selectors/selectSessionId';
import { selectCollectAnalytics } from '../redux/selectors';
import { withGoogleEvents } from '../hoc/withGoogleEvents';
import { IAnalytics } from '../models/UtilityPackMessage';
import { functionLibrary } from '../utils/FunctionLibrary';

export const eventServiceRef = React.createRef<EventService>();

export interface EventServiceProps {
	postEvents: typeof EndpointActions.actionCreators.postEvents;
	currentSceneIndex?: number;
	currentTime: number;
	duration: number;
	maximumTime: number;
	sessionId: string;
	collectAnalytics: boolean;
	sendGoogleAnalytics: (data: IAnalytics) => void;
}

class EventService extends React.Component<EventServiceProps> {
	private _buffer: Event[] = [];

	constructor(props: EventServiceProps) {
		super(props);

		const onSyncInterval = interval(5000);

		onSyncInterval.subscribe(() => this.sendEvents(true));
		onWindowBeforeUnload.subscribe(() => {
			const { currentSceneIndex, currentTime, duration, maximumTime } = this.props;
			if (hasValue(currentSceneIndex)) {
				this._buffer.push({
					type: EventType.VideoUnloaded,
					eventDateTimeOffset: getDateTimeOffset(),
					scene: currentSceneIndex,
					sceneTime: currentTime,
					duration: duration,
					maximumTime: maximumTime,
				});
			}
			this._buffer.push({
				type: EventType.PlayerLeave,
				eventDateTimeOffset: getDateTimeOffset(),
			});
			this.sendEvents(false);
		});
	}

	public logEvent = (event: Event) => {
		this._buffer.push(event);
		//trigger events to dom
		this.triggerEvent(event);
	};

	public shouldComponentUpdate = () => {
		return false;
	};

	public render = () => {
		return null;
	};

	private sendEvents = (async: boolean) => {
		if (this._buffer.length > 0) {
			const events = this._buffer;
			this._buffer = [];

			//only post events if collect analytics is true
			if (this.props.collectAnalytics) {
				const revertBuffer = () => {
					this._buffer.push(...events);
				};

				// Send buffer
				this.props.postEvents({ async, events }).then(
					(response) => {
						// If status code is not success revert items to buffer
						if (!(response.status >= 200 && response.status < 300)) {
							revertBuffer();
						}
					},
					() => {
						revertBuffer();
					}
				);

				// Send analytics
				this.sendAnalytics(events);
			}
		}
	};

	private triggerEvent = (event: Event) => {
		var eventDOMName = eventDOMTypeName[event.type];
			if (eventDOMName) functionLibrary.createEvent(eventDOMName);

	};

	private sendAnalytics = (events: Event[]) => {
		events.forEach((event: any) => {
			var eventName = eventTypeName[event.type];
			//only send events that are parsed
			if (eventName) {
				const dataToSend: IAnalytics = {
					type: 'analytics',
					analytics: 'VideoSmart',
					sessionId: this.props.sessionId,
					name: eventName,
					description: {
						maxValue: event.maxValue && event.maxValue,
						value: event.value && event.value,
						previousScene: event.previousScene && event.previousScene,
						tag: event.tag && event.tag,
						language: event.language && event.language,
						duration: event.duration && event.duration,
						seekedFrom: event.seekedFrom && event.seekedFrom,
						seekedTo: event.seekedTo && event.seekedTo,
						maximumTime: event.maximumTime && event.maximumTime,
						volumeFrom: event.volumeFrom && event.volumeFrom,
						volumeTo: event.volumeTo && event.volumeTo,
					},
				};
				this.props.sendGoogleAnalytics(dataToSend);
			}
		});
	};
}

const mapStateToProps = (state: RootState): Pick<EventServiceProps, 'currentSceneIndex' | 'currentTime' | 'duration' | 'maximumTime' | 'sessionId' | 'collectAnalytics'> => ({
	currentSceneIndex: state.context.currentSceneIndex,
	currentTime: state.video.currentTime,
	duration: state.video.duration,
	maximumTime: state.video.maximumTime,
	sessionId: selectSessionId(state),
	collectAnalytics: selectCollectAnalytics(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<EventServiceProps, 'postEvents'> => ({
	postEvents: bindActionCreators(EndpointActions.actionCreators.postEvents, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, { forwardRef: true })(withGoogleEvents(EventService));
