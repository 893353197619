import { EvaluatedSubtitleTrack } from '@videosmart/player-template';
import { createSelector } from 'reselect';

import { CachedSubtitleTrack } from '../../models';
import { RootState } from '../models';
import { selectCurrentScene } from './selectCurrentScene';

const padLeft = 0.4;      // Time (in seconds) subtracted from each cues start time.
const padRight = 0;       // Time (in seconds) added to each cues length.
const expiryPeriod = 5;   // Time (in seconds) allowed for each cue to stay in flow, if not removed by a new one.

export const selectCurrentSubtitleTrack = createSelector(
  (state:RootState) => state.context.selectedSubtitleLanguage,
  (state:RootState) => {
    const currentScene = selectCurrentScene(state);
    if(currentScene && currentScene.subtitles) {
      return currentScene.subtitles;
    } else {
      return [];
    }
  },
  (selectedSubtitleLanguage: string | undefined, subtitleTracks: EvaluatedSubtitleTrack[]) => {
    if (selectedSubtitleLanguage && subtitleTracks) {
      for (let subtitleTrack of subtitleTracks) {
        if (subtitleTrack.language === selectedSubtitleLanguage) {
          return {
            label: subtitleTrack.label,
            language: subtitleTrack.language,
            cues: subtitleTrack.cues
              .sort((a, b) => a.start - b.start)
              .map(({ text, start, length }, index) => ({
                index,
                text,
                start: start - padLeft,
                end: start + length + padRight + expiryPeriod,
                expire: start + length + padRight
              }))
          } as CachedSubtitleTrack;
        }
      }
    }
  }
);