import { EvaluatedControlOptions } from '@videosmart/player-template';

import { RootState } from '../models';
import { selectEvaluatedTemplate } from './selectEvaluatedTemplate';

const defaultControlOptions = {
  enableDownload: false,
  enableFullscreen: true,
  enableSubtitles: true,
  enableControls: true,
};

export const selectControlOptions = (state: RootState): EvaluatedControlOptions => {
  const template = selectEvaluatedTemplate(state);
  if(template) {
    return template.controlOptions;
  } else {
    return defaultControlOptions;
  }
};