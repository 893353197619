import { createSelector } from 'reselect';

import { CachedSubtitleTrack, DisplayedSubtitleCue } from '../../models';
import { RootState } from '../models';
import { selectCurrentSubtitleTrack } from './selectCurrentSubtitleTrack';

export const selectDisplayedSubtitleCues = createSelector(
  (state:RootState) => state.video.currentTime,
  (state:RootState) => selectCurrentSubtitleTrack(state),
  (currentTime:number, subtitleTrack:CachedSubtitleTrack | undefined) => {
    if(subtitleTrack) {
      const cues = subtitleTrack.cues
        .map((cue) => ({
          index: cue.index,
          text: cue.text,
          isEnded: cue.end <= currentTime,
          isExpired: cue.expire <= currentTime,
          isStarted: cue.start <= currentTime,
          placement: 0
        }));

      let placement = 0;  

      for(let i = cues.length - 1; i >= 0; i--) {
        const cue = cues[i];
        if(placement > 0 || (cue.isStarted && !cue.isEnded)) {
          cue.placement = Math.min(3, ++placement);
        } else {
          cue.placement = cue.isStarted ? 3 : 0;
        }
      }

      return cues as DisplayedSubtitleCue[];
    }
    
    return [] as DisplayedSubtitleCue[];
  }
);