import queryString  from 'query-string';

import { RouteParams } from '../../models';
import { RootState } from '../models';
import { createSelector } from 'reselect';

export const selectRouteParams = createSelector(
  (state:RootState) => state.router.location.search,
  (search:string) => {
    const params = queryString.parse(search);

    const playerId = Array.isArray(params.v) ? params.v[0] : params.v || '';
    const allowTracking = (Array.isArray(params.t) ? params.t[0] : params.t || '') !== '0';
    const recordId = Array.isArray(params.r) ? params.r[0] : params.r || '';
    const variables = Array.isArray(params.variables) ? params.variables[0] : params.variables || '';

    return {
      allowTracking,
      playerId,
      recordId,
      variables
    } as RouteParams;
  }
);