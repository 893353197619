import { EvaluatedSubtitleOptions } from '@videosmart/player-template';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Subscription } from 'rxjs';

import { DisplayedSubtitleCue } from '../models';
import { RootState } from '../redux/models';
import {
  selectAreSubtitlesEnabled,
  selectDisplayedSubtitleCues,
  selectIsControlBarVisible,
  selectSubtitleOptions,
} from '../redux/selectors';
import { measureTextWidth, onWindowResize } from '../utils';
import { classes } from './DynamicStyle';
import styles from './SubtitleDisplay.module.scss';
import { SubtitlePosition } from '@videosmart/player-template/lib/enums/SubtitlePosition';

export interface SubtitleDisplayProps {
  areSubtitlesEnabled: boolean;
  cues: DisplayedSubtitleCue[];
  isControlBarVisible: boolean;
  options: EvaluatedSubtitleOptions;
}

interface SubtitleDisplayState {
  fontSize: number;
}

class SubtitleDisplay extends React.Component<SubtitleDisplayProps, SubtitleDisplayState> {
  private readonly _cueContainerRef: React.RefObject<HTMLDivElement>;

  private readonly _maxFontSize: number;

  private _subscriptions: Subscription[];

  constructor(props: SubtitleDisplayProps) {
    super(props);

    this._cueContainerRef = React.createRef();
    this._maxFontSize = 24;
    this._subscriptions = [];

    this.state = {
      fontSize: this._maxFontSize
    };
  }

  private get fontFamily() {
    const { options } = this.props;
    const defaultFontFamily = "'Open Sans', sans-serif";
    return options.fontFamily ? `'${options.fontFamily}', ${defaultFontFamily}` : defaultFontFamily;
  }

  public componentDidMount = () => {
    this.recalculateFontSize();
    this._subscriptions.push(onWindowResize.subscribe(this.handleWindowResize));
  }

  public componentDidUpdate = (prevProps: SubtitleDisplayProps) => {
    if (this.props.cues !== prevProps.cues) {
      this.recalculateFontSize();
    }
  }

  public componentWillUnmount = () => {
    for(let subscription of this._subscriptions) {
      subscription.unsubscribe();
    }
    this._subscriptions = [];
  }

  public render = () => {
    const { areSubtitlesEnabled, cues, isControlBarVisible, options } = this.props;
    const { fontSize } = this.state;
    
    const fontFamily = this.fontFamily;

    const rootClassName = classNames(styles["root"], {
      [styles["lifted"]]: options.position === SubtitlePosition.Bottom && isControlBarVisible,
      [styles["hidden"]]: !areSubtitlesEnabled,
      [styles["position-bottom"]]: options.position === SubtitlePosition.Bottom,
      [styles["position-top"]]: options.position === SubtitlePosition.Top
    });
    const displayClassName = classNames(styles["display"], classes.background.backgroundColor);
    const cueContainerClassName = classNames(styles["cue-container"], classes.textShadow);
    
    const subtitleCues = cues.map((cue) => {
      const className = classNames(styles["cue"], {
        [styles["bottom"]]: cue.placement === 0,
        [styles["first"]]: cue.placement === 1,
        [styles["second"]]: cue.placement === 2,
        [styles["top"]]: cue.placement === 3,
        [styles["expired"]]: cue.isExpired,
        [styles["ended"]]: cue.isEnded
      });

      return (
        <div key={cue.index} className={className}>
          {cue.text}
        </div>
      );
    });

    return (
      <div className={rootClassName}>
        <div
          className={displayClassName}
          style={{
            fontFamily,
            fontSize
          }}
        >
          <div
            className={cueContainerClassName}
            ref={this._cueContainerRef}
          >
            {subtitleCues}
          </div>
        </div>
      </div>
    );
  }

  private handleWindowResize = () => {
    this.recalculateFontSize();
  }

  private recalculateFontSize = () => {
    const cueContainer = this._cueContainerRef.current;

    if(cueContainer) {
      const { cues } = this.props;
      const maxFontSize = this._maxFontSize;
      
      // Maximum width of all cues in the subtitle track
      const maxCueWidth = cues
        .map(cue => measureTextWidth(cue.text, this.fontFamily, maxFontSize))
        .reduce((max, value) => Math.max(max, value), maxFontSize);
  
      // Calculate font size
      const scaleFont = cueContainer.offsetWidth / (maxCueWidth * 1.2);
      const fontSize = Math.floor((scaleFont < 1) ? (maxFontSize * scaleFont) : (maxFontSize));
  
      this.setState(() => ({
        fontSize
      }));
    }
  }
}


const mapStateToProps = (state: RootState): Pick<SubtitleDisplayProps, 'areSubtitlesEnabled' | 'cues' | 'isControlBarVisible' | 'options'> => ({
  areSubtitlesEnabled: selectAreSubtitlesEnabled(state),
  cues: selectDisplayedSubtitleCues(state),
  isControlBarVisible: selectIsControlBarVisible(state),
  options: selectSubtitleOptions(state)
});

export default connect(mapStateToProps)(SubtitleDisplay);