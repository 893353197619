import { ActionCreator } from 'typescript-fsa';
import { AsyncWorker } from 'typescript-fsa-redux-thunk';

import { EndpointActions } from '..';
import { ErrorType } from '../../../enums';
import { LoadSceneEventArgs, SceneLoadedEvent } from '../../../models';
import { hasValue } from '../../../utils';
import { RootState } from '../../models';
import { selectEvaluatedTemplate } from '../../selectors';


export const loadSceneWorker = (unloadScene: ActionCreator<void>): AsyncWorker<LoadSceneEventArgs, SceneLoadedEvent, RootState> => (params, dispatch, getState) => {
  const state = getState();
  const template = selectEvaluatedTemplate(state);

  if (!state.data.isLoaded || !template) {
    throw new Error("No data is loaded!");
  }

  const previousSceneIndex = state.context.currentSceneIndex;

  // If previous scene exist, raise a scene unload action.
  if (hasValue(previousSceneIndex)) {
    dispatch(unloadScene());
  }

  const sceneId = (params && params.sceneId) || template.defaultSceneId || template.scenes[0].id;
  const sceneIndex = template.scenes.findIndex((scene) => scene.id === sceneId);

  if (sceneIndex === -1) {
    dispatch(EndpointActions.asyncActionCreators.logPlayerErrorCreator.action({ type: ErrorType.SceneNotFound }));
    throw new Error("Scene cannot be found!");
  }

  const scene = template.scenes[sceneIndex];

  return {
    scene,
    sceneIndex
  };
};