import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { ContextActions, VideoActions } from '../actions';
import { VideoState } from '../models';
import { hasValue } from '../../utils';

const initialState: VideoState = {
  aspectRatio: 16 / 9,
  buffered: [],
  currentTime: 0,
  duration: 0,
  isEnded: false,
  isLoading: false,
  isLooped: false,
  isMuted: false,
  isPlaying: false,
  maximumTime: 0,
  volume: 1,
  autoPlay: false
};

export const videoReducer = reducerWithInitialState(initialState)
  // Context
    .case(ContextActions.asyncActionCreators.loadSceneCreator.async.done, (state, { params }:any) => ({
    ...state,
    buffered: [],
    currentTime: 0,
    //duration: 0,
      maximumTime: 0,
      isPlaying: false,
      isEnded: false,
      autoPlay: params && hasValue(params.autoPlay) ? params.autoPlay : false
  }))
  
  // Video
  .case(VideoActions.actionCreators.logIsEnded, (state, payload) => ({
    ...state,
    isEnded: payload
  }))
  .case(VideoActions.actionCreators.logIsLoaded, (state, { aspectRatio, duration }) => ({
    ...state,
    aspectRatio,
    duration,
    maximumTime: 0
  }))
  .case(VideoActions.actionCreators.logIsPlaying, (state, payload) => ({
    ...state,
    isEnded: payload ? false : state.isEnded,
    isLoading: payload ? false : state.isLoading,
    isPlaying: payload
  }))
  .case(VideoActions.actionCreators.logProgress, (state, payload) => ({
    ...state,
    buffered: payload
  }))
  .case(VideoActions.actionCreators.logTimeUpdate, (state, payload) => ({
    ...state,
    currentTime: payload,
    isEnded: payload === state.duration,
    maximumTime: Math.max(state.maximumTime, payload),
    isLoading: false
  }))
  .case(VideoActions.actionCreators.logWaiting, (state) => ({
    ...state,
    isLoading: true
  }))
  .case(VideoActions.actionCreators.setVolume, (state, { isMuted, volume }) => ({
    ...state,
    isMuted: hasValue(isMuted) ? isMuted : state.isMuted,
    volume: hasValue(volume) ? Math.max(0, Math.min(1, volume)) : state.volume
  }))