import { EvaluatedVideoSource } from '@videosmart/player-template';

import { RootState } from '../models';
import { selectCurrentScene } from './selectCurrentScene';

export const selectCurrentVideoSrc = (state: RootState): EvaluatedVideoSource | undefined => {
  const currentScene = selectCurrentScene(state);
  if(currentScene) {
    return currentScene.videoSource;
  }
};