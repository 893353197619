import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { EndpointActions } from '../actions';
import { DataState } from '../models';

const initialState: DataState = {
  isLoading: false,
  isLoaded: false
};

export const dataReducer = reducerWithInitialState<DataState>(initialState)
  // Endpoint
  .case(EndpointActions.asyncActionCreators.getPlayerCreator.async.started, (state) => ({
    ...state,
    isLoaded: false,
    isLoading: true
  }))
  .case(EndpointActions.asyncActionCreators.getPlayerCreator.async.done, (state, { result }) => result.ok ? ({
    ...state,
    environment: result.environment,
    isLoaded: true,
    isLoading: false,
    playerId: result.playerId,
    sessionId: result.sessionId,
    template: result.template,
    variables: result.variables,
    collectAnalytics: result.collectAnalytics
  }) : ({
    ...state,
    isLoaded: false,
    isLoading: false,
  }));
