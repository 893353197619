import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ContextActions } from "..";
import { AuthTokenState, RootState } from "../../models";

export const getAuthToken = async (scope: string, getState: () => RootState, dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<any> => {
    const authToken = getState().context?.authToken;
    //validate if state contains a valid token
    if(isAuthTokenValid(authToken)){
        return authToken?.access_token;
    }
    //get initial date
    const currentDate = new Date();
	const url = `${process.env.REACT_APP_ACCOUNTS_DOMAIN}/connect/token`;
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
		body: new URLSearchParams({
			client_id: `${process.env.REACT_APP_ACCOUNTS_CLIENT_ID}`,
			client_secret: `${process.env.REACT_APP_ACCOUNTS_CLIENT_SECRET}`,
			grant_type: "client_credentials",
			scope: scope,
		}),
	}).catch((ex) => {
		throw new Error("Something went wrong! Please try again.");
	});
	const responseJson = await response?.json();
	if (!responseJson || !responseJson?.access_token) {
		throw new Error("Something went wrong! Please try again.");
	}
    //add token expiration in seconds
    currentDate.setSeconds(currentDate.getSeconds()+responseJson.expires_in);
    //create token state
    const newAuthTokenState : AuthTokenState = { access_token: createToken(responseJson), expires_in: currentDate, scope: responseJson.scope };
    dispatch(ContextActions.actionCreators.updateAuthToken(newAuthTokenState));
	return newAuthTokenState.access_token;
};


const isAuthTokenValid = (authToken: AuthTokenState|undefined) : boolean => authToken !== undefined && authToken !== null && authToken.expires_in > new Date();
const createToken = (responseJson:any) => `${responseJson.token_type} ${responseJson.access_token}`