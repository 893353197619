import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/models';
import { selectFormattedCurrentTime, selectFormattedDuration } from '../redux/selectors';
import { classes } from './DynamicStyle';
import styles from './TimeDisplay.module.scss';

export interface TimeDisplayProps {
  formattedCurrentTime: string;
  formattedDuration: string;
}

const TimeDisplay: React.FC<TimeDisplayProps> = (props: TimeDisplayProps) => {
  const className = classNames(styles["root"], classes.textShadow);

  return (
    <div className={className}>
      {props.formattedCurrentTime} / {props.formattedDuration}
    </div>
  );
}

const mapStateToProps = (state: RootState): Pick<TimeDisplayProps, 'formattedCurrentTime' | 'formattedDuration'> => ({
  formattedCurrentTime: selectFormattedCurrentTime(state),
  formattedDuration: selectFormattedDuration(state)
});

export default connect(mapStateToProps)(TimeDisplay);
